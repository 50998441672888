import { useCallback, useState } from 'react';

type Status = 'error' | 'success' | 'default';

export const useCopyToClipboard = () => {
  const [error, setError] = useState<Error>();
  const [status, setStatus] = useState<Status>('default');

  const isSuccess = status === 'success';

  const onCopy = useCallback(async (text: string) => {
    let _status: Status = 'default';
    let _error: Error | undefined;
    setStatus(_status);
    try {
      await navigator.clipboard.writeText(text);
      _status = 'success';
    } catch (e: any) {
      _status = 'error';
      _error = e;
    } finally {
      setError(_error);
      setStatus(_status);
    }

    return { status: _status, error: _error };
  }, []);

  return [onCopy, { error, status, isSuccess }] as const;
};
