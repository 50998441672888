import { AxiosResponse } from 'axios';
import { orderBy, select } from 'utils/dynamic';
import { API, apiRtk, rtkAdapterDynamicToSource } from 'utils/service';
import { IProductBrand } from '__generated__/api';

export const apiProductBrands = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceProductBrands: build.query({
      queryFn: async (arg: void) => {
        const res = await API.api.productBrandsGetAllDynamicList({
          Select: select('id', 'catalogName as title', 'isActive'),
          OrderBy: orderBy('catalogName', 'asc'),
        });

        return rtkAdapterDynamicToSource(res) as AxiosResponse<
          Array<Pick<IProductBrand, 'id' | 'isActive'> & { title: string }>
        >;
      },
    }),
  }),
});
