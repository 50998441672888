import { DateValue } from 'utils/dates';
import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import { IPharmaCompanyOrder, IPharmaCompanyOrderDynamicResponse } from '__generated__/api';

type SchemaFields = Pick<
  IPharmaCompanyOrder,
  | 'orderNumber'
  | 'startDate'
  | 'endDate'
  | 'orderStatusID'
  | 'countryID'
  | 'pharmaCompanyID'
  | 'pricePerDay'
  | 'totalPrice'
  | 'maxProducts'
  | 'isActive'
>;
export interface ProductCompanyOrderInputPost extends SchemaFields {}

export const schemaProductCompanyOrder = yup.object({
  orderNumber: yup.string().nullable().required('rule-required'),
  startDate: yup.string().required('rule-required'),
  endDate: yup.string().required('rule-required'),
  orderStatusID: yup.string().required('rule-required'),
  countryID: yup.string().required('rule-required'),
  pharmaCompanyID: yup.string().required('rule-required'),
  pricePerDay: yup.number().nullable().typeError('rule-number'),
  totalPrice: yup.number().typeError('rule-number'),
  maxProducts: yup.number().typeError('rule-number'),
  isActive: yup.boolean().required('rule-required'),
});

export const schemaProductCompanyOrderPatch = schemaProductCompanyOrder.shape({
  tenantIDs: yup.array().of(yup.string()).min(1, 'rule-required').required('rule-required'),
  id: yup.string().required('rule-required'),
});

export interface ProductCompanyOrderGet
  extends yup.InferType<typeof schemaProductCompanyOrderPatch>,
    Pick<IPharmaCompanyOrder, 'pharmaCompany'> {
  id: string;
}

export interface ProductCompanyOrderInputPatch
  extends yup.InferType<typeof schemaProductCompanyOrderPatch> {
  id: string;
}

export interface IGridPharmaCompanyOrder
  extends Pick<
    IPharmaCompanyOrder,
    | 'pharmaCompanyID'
    | 'orderNumber'
    | 'startDate'
    | 'endDate'
    | 'maxProducts'
    | 'totalPrice'
    | 'pricePerDay'
    | 'isActive'
    | 'id'
  > {
  orderStatusTitle: string;
  pharmaCompanyName: string;
  countryName: string;
  totalBudgetUsed: number;
  numberOfProducts: number;
  pharmaCompanyOrderID: string;
  countryFlag: string;
}

export interface IGridPharmaCompanyOrderResponse
  extends Omit<IPharmaCompanyOrderDynamicResponse, 'items'> {
  items: IGridPharmaCompanyOrder[];
}

export interface IPharmaCompanyOrderInfoResponse extends IPharmaCompanyOrder {
  countryName: string;
  pharmaCompanyName: string;
  numberOfProducts: number;
  totalBudgetUsed: number;
}

export interface IGridPharmaCompanyOrderFilters {
  tenantID: string;
  date: DateValue[];
  search?: string;
  countryID?: string;
  isActive: boolean | null;
}
export interface IGridPharmaCompanyOrderArgs extends IGridPharmaCompanyOrderFilters {
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
}
