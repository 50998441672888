import {
  differenceInMilliseconds,
  hoursToMilliseconds,
  isValid,
  max,
  millisecondsToHours,
  millisecondsToMinutes,
  millisecondsToSeconds,
  min,
  minutesToMilliseconds,
  secondsToMilliseconds,
  toDate as fnsToDate,
} from 'date-fns';

export type DateValue = Date | string | number | null | undefined;

export const isDateLike = (value: any): value is string | number | Date => {
  if (!value) return false;
  if (typeof value === 'string') {
    return isValid(new Date(value));
  }
  return isValid(fnsToDate(value));
};

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};

export const minDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return min(values);
};
export const maxDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return max(values);
};

export const appIntervalToDuration = (interval: Interval) => {
  let next = Math.abs(differenceInMilliseconds(interval.start, interval.end));

  const hours = millisecondsToHours(next);
  next = next - hoursToMilliseconds(hours);

  const minutes = millisecondsToMinutes(next);
  next = next - minutesToMilliseconds(minutes);

  const seconds = millisecondsToSeconds(next);
  next = next - secondsToMilliseconds(seconds);

  const milliseconds = next;

  return { hours, minutes, seconds, milliseconds };
};
