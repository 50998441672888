import { AxiosResponse } from 'axios';
import { DynamicResult } from 'modules/dynamic-service';
import { IGridBotTheme, IGridBotThemeInput } from 'services/bot-themes/models';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IBotTheme } from '__generated__/api';

const REVALIDATE_KEY = 'BotThemes' as const;

const dynamic = dynamicNamespace<IBotTheme>();

export const apiBotThemes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceBotThemes: build.query({
      queryFn: async (arg: void) => {
        const response = await API.api.botThemesGetAllDynamicList({
          Select: dynamic.select('id', 'title'),
          Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
          OrderBy: dynamic.orderBy('title', 'asc'),
        });
        return rtkAdapterDynamicToSource(response);
      },
    }),

    getGridBotThemes: build.query({
      queryFn: async ({
        search,
        take: Take,
        skip: Skip,
        orderBy,
        isActive,
      }: IGridBotThemeInput) => {
        const response = await API.api.botThemesGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter(['title', 'description'], search, contains),
            dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
          ).join('&&'),
          Select: dynamic.select('id', 'title', 'description', 'isActive'),
          OrderBy: dynamic.orderBy(orderBy),
          Take,
          Skip,
          Count: true,
        });
        return response as AxiosResponse<DynamicResult<IGridBotTheme>>;
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),

    getBotTheme: build.query({
      queryFn: async (id: string) => {
        const result = await API.api.botThemesGetAllDynamicList({
          Filter: dynamic.makeFilter('id', id, equals),
          Take: 1,
        });
        return rtkAdapterDynamicItem(result);
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postBotTheme: build.mutation({
      queryFn: (data: Partial<IBotTheme>) => API.api.botThemesCreateCreate(data),
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchBotTheme: build.mutation({
      queryFn: (data: PatchPartial<IBotTheme, 'id'>) =>
        API.api.botThemesPatchPartialUpdate(...makePatchArgs(data)),
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteBotTheme: build.mutation({
      queryFn: (data: PatchPartial<IBotTheme, 'id'>) =>
        API.api.botThemesDeleteDelete(String(data?.id)),
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});

export * from './models';
