import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceBiddingBoard } from 'services/bidding-boards';
import { BotSessionDosageForm, ServiceDosageFormSessions } from 'services/bot-session-dosage-forms';
import { BotSessionLogUserActionKeyItem, ServiceBotSessionLogs } from 'services/bot-session-logs';
import { BotSessionProductItem, ServiceBotSessionsProducts } from 'services/bot-session-products';
import { BotSessionAnalyticsItem, ServiceBotSessions } from 'services/bot-sessions';
import { ServiceBotUserProfiles } from 'services/bot-user-profiles';
import { ServiceTenantIgnoredProducts } from 'services/tenant-ignored-products';
import { selectAccountLanguageID } from 'store/auth';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { DashboardSessionsArgs } from './slice';

export const actionGetNumberOfBots = createAsyncThunk<
  number,
  { tenantID: string },
  AppAsyncThunkConfig
>(`DASHBOARD/actionGetNumberOfBots`, async (args) => {
  try {
    const { data } = await ServiceBotUserProfiles.getNumberOfBots(args);
    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionGetNumberOfProducts = createAsyncThunk<
  number,
  { tenantID: string },
  AppAsyncThunkConfig
>(`DASHBOARD/actionGetNumberOfProducts`, async (args) => {
  try {
    const { data } = await ServiceBiddingBoard.getNumberOfProducts(args);
    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionGetNumberOfIgnoredProducts = createAsyncThunk<
  number,
  { tenantID: string },
  AppAsyncThunkConfig
>(`DASHBOARD/actionGetNumberOfIgnoredProducts`, async (args) => {
  try {
    const { data } = await ServiceTenantIgnoredProducts.getNumberOfIgnoredProducts(args);
    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionGetDashboardSessionAnalytics = createAsyncThunk<
  BotSessionAnalyticsItem[],
  DashboardSessionsArgs,
  AppAsyncThunkConfig
>(`DASHBOARD/actionGetDashboardSessionAnalytics`, async (args, { getState }) => {
  try {
    const languageID = selectAccountLanguageID(getState());

    if (!languageID) {
      throw new Error('unexpected-behaviour');
    }

    const { data } = await ServiceBotSessions.getDashboardSessionAnalytics({
      ...args,
      languageID,
    });

    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionGetDashboardDosageFormSession = createAsyncThunk<
  BotSessionDosageForm[],
  DashboardSessionsArgs,
  AppAsyncThunkConfig
>(`DASHBOARD/actionGetDashboardDosageFormSession`, async (args, { getState }) => {
  try {
    const languageID = selectAccountLanguageID(getState());

    if (!languageID) {
      throw new Error('unexpected-behaviour');
    }

    const { data } = await ServiceDosageFormSessions.getDashboardDosageFormSession({
      ...args,
      languageID,
    });
    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionGetDashboardSessionProducts = createAsyncThunk<
  BotSessionProductItem[],
  DashboardSessionsArgs,
  AppAsyncThunkConfig
>(`DASHBOARD/actionGetDashboardSessionProducts`, async (args, { getState }) => {
  try {
    const languageID = selectAccountLanguageID(getState());

    if (!languageID) {
      throw new Error('unexpected-behaviour');
    }

    const { data } = await ServiceBotSessionsProducts.getDashboardSessionProducts({
      ...args,
      languageID,
    });

    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionGetDashboardBotSessionLogs = createAsyncThunk<
  BotSessionLogUserActionKeyItem[],
  DashboardSessionsArgs,
  AppAsyncThunkConfig
>(`DASHBOARD/actionGetDashboardBotSessionLogs`, async (args, { getState }) => {
  try {
    const languageID = selectAccountLanguageID(getState());

    if (!languageID) {
      throw new Error('unexpected-behaviour');
    }

    const { data } = await ServiceBotSessionLogs.getDashboardBotSessionLogs({
      ...args,
      languageID,
    });
    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
