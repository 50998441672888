import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { apiBiddingBoard } from 'services/bidding-boards';
import { apiPharmaCompanyOrders } from 'services/pharma-company-orders';
import { selectAccountTenantID } from 'store/auth';
import { getSearchFilter } from 'utils/front-filters';
import { createMap } from 'utils/other';
import { calcPaginationState } from 'utils/service';
import { ArrayType } from 'utils/types';
import { AppState } from '../index';

const selectState = (state: AppState) => state.orderBoard;

export const selectOrderBoardFilters = createSelector(selectState, (state) => ({
  botIDs: state.botIDs,
  pharmaCompanyOrderID: state.pharmaCompanyOrderID,
  botCaseID: state.botCaseID,
  search: state.search,
}));
export const selectOrderBoardSettings = createSelector(selectState, (state) => ({
  isHidePrices: state.isHidePrices,
  isShowPreview: state.isShowPreview,
}));
export const selectOrderBoardStatuses = createSelector(selectState, (state) => ({
  isInit: state.isInit,
  isDragging: state.isDragging,
  maxProducts: state.maxPositions,
}));
export const selectOrderBoardOrderInfo = createSelector(
  (state: AppState) => state,
  selectAccountTenantID,
  selectOrderBoardFilters,
  (state, tenantID, { pharmaCompanyOrderID }) =>
    apiPharmaCompanyOrders.endpoints.getPharmaCompanyOrderInfo.select({
      id: pharmaCompanyOrderID || '',
      tenantID: tenantID || '',
    })(state),
);

export const selectOrderBoardOrderCases = createSelector(
  (state: AppState) => state,
  (state: AppState) => {
    const {
      account: { tenantID },
    } = state;
    return apiBiddingBoard.endpoints.getBiddingBoardCases.select({ tenantID })(state);
  },
);
const selectOrderBoardOrderCasesIsLoading = createSelector(
  selectOrderBoardOrderCases,
  ({ status }) => status === QueryStatus.pending,
);
export const selectOrderBoardOrderCasesGraph = createSelector(
  selectOrderBoardOrderCases,
  ({ data: listCases = [] }) => {
    type Result = {
      [botCaseID: string]: {
        id: string;
        title: string;
        bots: {
          [botID: string]: {
            id: string;
            title: string;
            pharmaCompanyOrder: { [pharmaCompanyOrderID: string]: ArrayType<typeof listCases> };
          };
        };
      };
    };
    return listCases.reduce((acc, item) => {
      const pharmaCompanyOrderID = String(item.pharmaCompanyOrderID);
      const botID = String(item.botID);
      const botCaseID = String(item.botCaseID);

      if (!acc[botCaseID]) {
        acc[botCaseID] = {
          id: botCaseID,
          title: String(item.caseCatalogName),
          bots: {},
        };
      }

      if (!acc[botCaseID].bots[botID]) {
        acc[botCaseID].bots[botID] = {
          id: botID,
          title: String(item.botName),
          pharmaCompanyOrder: {},
        };
      }

      acc[botCaseID].bots[botID].pharmaCompanyOrder[pharmaCompanyOrderID] = item;

      return acc;
    }, {} as Result);
  },
);
export const selectOrderBoardOrderCasesSource = createSelector(
  selectOrderBoardOrderCasesGraph,
  selectOrderBoardOrderCasesIsLoading,
  (casesGraph, isLoading) => {
    const data = Object.values(casesGraph);

    return { data, isLoading };
  },
);

export const selectOrderBoardOrderBotsSource = createSelector(
  selectOrderBoardOrderCasesSource,
  selectOrderBoardFilters,
  ({ isLoading, data: orders }, { botCaseID }) => {
    if (!botCaseID) {
      return { data: [], isLoading };
    }
    const botsMap = orders.find((item) => item.id === botCaseID)?.bots || {};

    const data = Object.values(botsMap);

    return { data, isLoading };
  },
);

export const selectOrderBoardOrderOrdersSource = createSelector(
  selectOrderBoardOrderBotsSource,
  selectOrderBoardFilters,
  ({ isLoading, data: bots }, { botIDs }) => {
    if (!botIDs[0]) {
      return { data: [], isLoading };
    }
    const botsMap = bots.find((item) => item.id === botIDs[0])?.pharmaCompanyOrder || {};

    const data = Object.values(botsMap);

    return { data, isLoading };
  },
);

export const selectOrderBoardOrderProductsIsLoading = createSelector(
  selectState,
  ({ productsLoading }) => productsLoading,
);
export const selectOrderBoardOrderProducts = createSelector(
  selectState,
  ({ products }) => products,
);
export const selectOrderBoardOrderProductsAll = createSelector(
  selectState,
  ({ productsAll }) => productsAll,
);
export const selectOrderBoardOrderProductsCurrentBot = createSelector(
  selectOrderBoardOrderProducts,
  selectOrderBoardFilters,
  (products, { botIDs }) =>
    products.filter((product) => product?.botCase?.appIdentityUserID === botIDs[0]),
);
export const selectOrderBoardOrderProductsAllPositions = createSelector(
  selectOrderBoardOrderProductsCurrentBot,
  (products) =>
    products.reduce(
      (acc, item) => {
        const positionIndex = Number(item.positionIndex);

        if (!acc[positionIndex]) {
          acc[positionIndex] = [];
        }

        acc[positionIndex].push(item);

        return acc;
      },
      {} as { [positionIndex: number]: typeof products },
    ),
);

export const selectOrderBoardOrderProductsCurrent = createSelector(
  selectOrderBoardOrderProducts,
  selectOrderBoardFilters,
  (products, { pharmaCompanyOrderID, botIDs }) =>
    products.filter(
      (product) =>
        product.pharmaCompanyOrderID === pharmaCompanyOrderID &&
        product?.botCase?.appIdentityUserID === botIDs[0],
    ),
);
export const selectOrderBoardOrderProductsCurrentMap = createSelector(
  selectOrderBoardOrderProductsCurrent,
  (products) => createMap(products, 'id'),
);

export const selectOrderBoardOrderProductsList = createSelector(
  selectOrderBoardOrderProductsCurrent,
  selectOrderBoardFilters,
  (products, { search }) =>
    products.filter(
      getSearchFilter<ArrayType<typeof products>>({
        value: search,
        fields: ['productName', 'productSerialNumber'],
      }),
    ),
);

export const selectOrderBoardUpdatedIDs = createSelector(
  selectOrderBoardFilters,
  selectOrderBoardOrderBotsSource,
  ({ botIDs }, bots) => {
    if (!bots.data?.length) {
      return [];
    }

    return bots.data
      .map((bot) => {
        return { ...bot, isChecked: botIDs.some((botID) => botID === bot.id) };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  },
);

export const selectOrderBoardOrderProductsGraph = createSelector(
  selectOrderBoardOrderProducts,
  (products) => {
    type Result = {
      [id: string]: {
        pharmaCompanyOrderID: string | null | undefined;
        positionIndex: number | undefined;
      };
    };
    return products.reduce((acc, item) => {
      const id = String(item.id);

      if (!acc[id]) {
        acc[id] = {
          pharmaCompanyOrderID: item.pharmaCompanyOrderID,
          positionIndex: item.positionIndex,
        };
      }

      return acc;
    }, {} as Result);
  },
);

export const selectOrderBoardOrderProductsPreview = createSelector(
  selectOrderBoardOrderProducts,
  selectOrderBoardFilters,
  (orderProducts, { botIDs }) => {
    type Result = {
      [id: string]: {
        positionIndex: number | undefined;
      };
    };
    return orderProducts.reduce((acc, product) => {
      if (product?.botCase?.appIdentityUserID === botIDs[0]) {
        const id = String(product.botCaseTenantProductID);
        if (!acc[id]) {
          acc[id] = {
            positionIndex: product.positionIndex,
          };
        }
      }
      return acc;
    }, {} as Result);
  },
);

export const selectOrderBoardOrderNotPromotedProducts = createSelector(
  selectOrderBoardOrderProductsAll,
  selectOrderBoardOrderProductsPreview,
  (productsPreview, currentBotProduct) =>
    productsPreview.filter((item) => {
      if (!currentBotProduct[item.id]) {
        return true;
      }

      return !((currentBotProduct[item.id]?.positionIndex ?? -1) >= 0);
    }),
);

export const selectOrderBoardPreviewPagination = createSelector(
  selectState,
  ({ page, take, previewItems }) => {
    const paginationState = calcPaginationState({ take, page, count: previewItems.length });

    const startIndex = paginationState.skip;
    const endIndex =
      previewItems.length > take + startIndex ? take + startIndex : previewItems.length;

    const updatedItems = previewItems.slice(startIndex, endIndex).filter(Boolean);

    return { items: updatedItems, ...paginationState };
  },
);

export const selectOrderBoardOrderProductsAllIsLoading = createSelector(
  selectState,
  ({ productsAllLoading }) => productsAllLoading,
);
export const selectOrderBoardOrderCurrentBotID = createSelector(
  selectState,
  ({ botIDs }) => botIDs?.[0],
);
