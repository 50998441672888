import { dynamicNamespace } from 'utils/dynamic';
import { API, apiRtk } from 'utils/service';
import { IOrderStatuses } from './models';

const dynamic = dynamicNamespace<IOrderStatuses>();

export * from './models';
export const apiOrderStatuses = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceOrderStatuses: build.query<IOrderStatuses[], void>({
      queryFn: async () => {
        try {
          const { data } = await API.api.orderStatusesGetAllDynamicList({
            Select: dynamic.select('id', 'title'),
            OrderBy: dynamic.orderBy('title', 'asc'),
          });
          return { data: data?.items || [] } as { data: IOrderStatuses[] };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
