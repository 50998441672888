import { SourceModel } from 'configs/types';
import { dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import { API, apiRtk, rtkAdapterDynamicItem, rtkAdapterDynamicToSource } from 'utils/service';
import { ITenant } from '__generated__/api';

const dynamic = dynamicNamespace<ITenant>();

export const apiTenants = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceTenants: build.query({
      queryFn: async (arg: void) => {
        const response = await API.api.tenantsGetAllDynamicList({
          Select: dynamic.select('id', 'companyName as title', 'isActive'),
          Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
          OrderBy: dynamic.orderBy('companyName', 'asc'),
        });
        return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
      },
    }),
    getTenantCountry: build.query({
      queryFn: async ({ tenantID }: { tenantID: string | null }) => {
        const response = await API.api.tenantsGetAllDynamicList({
          Select: dynamic.select('country.countryName as country, country.id as countryID'),
          Filter: mergeFilters(
            dynamic.makeFilter('isActive', true, equals),
            dynamic.makeFilter('id', tenantID, equals),
          ).join('&&'),
          Take: 1,
        });
        return rtkAdapterDynamicItem<{ country: string; countryID: string }>(
          response as unknown as any,
        );
      },
    }),
  }),
});
