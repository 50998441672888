import * as yup from 'modules/yup-extended';
import { DynamicOrder } from 'utils/dynamic';
import { IBotTheme } from '__generated__/api';

export const schemaBotTheme = yup.object({
  title: yup.string().required('rule-required').max(50, 'rule-max').trim().default(''),
  description: yup.string().nullable().notRequired().max(150, 'rule-max').trim().default(''),
  textPrimary: yup.string().nullable().notRequired().colorHex('color-hex').trim().default(''),
  textSecondary: yup.string().nullable().notRequired().colorHex('color-hex').trim().default(''),
  warningColor: yup.string().nullable().notRequired().colorHex('color-hex').trim().default(''),
  warningContrastColor: yup
    .string()
    .nullable()
    .notRequired()
    .colorHex('color-hex')
    .trim()
    .default(''),
  errorColor: yup.string().nullable().notRequired().colorHex('color-hex').trim().default(''),
  errorContrastColor: yup
    .string()
    .nullable()
    .notRequired()
    .colorHex('color-hex')
    .trim()
    .default(''),
  primaryColor: yup.string().nullable().notRequired().colorHex('color-hex').trim().default(''),
  primaryContrastColor: yup
    .string()
    .nullable()
    .notRequired()
    .colorHex('color-hex')
    .trim()
    .default(''),
  primaryGradientColor: yup
    .string()
    .nullable()
    .notRequired()
    .colorHex('color-hex')
    .trim()
    .default(''),
  secondaryColor: yup.string().nullable().notRequired().colorHex('color-hex').trim().default(''),
  secondaryContrastColor: yup
    .string()
    .nullable()
    .notRequired()
    .colorHex('color-hex')
    .trim()
    .default(''),
  secondaryGradientColor: yup
    .string()
    .nullable()
    .notRequired()
    .colorHex('color-hex')
    .trim()
    .default(''),
  bgPrimary: yup.string().nullable().notRequired().colorHex('color-hex').trim().default(''),
  bgSecondary: yup.string().nullable().notRequired().colorHex('color-hex').trim().default(''),
  isActive: yup.boolean().default(true),
});

export interface IGridBotThemeInput {
  search: string;
  take: number;
  orderBy: DynamicOrder;
  skip: number;
  isActive: boolean | null;
}
export interface IGridBotTheme
  extends Pick<IBotTheme, 'id' | 'title' | 'description' | 'isActive'> {}
