import { AxiosResponse } from 'axios';
import {
  BotSessionLogUserActionKeyItem,
  GetBotSessionLogsByBotSessionItem,
  USER_ACTION_KEY,
} from 'services/bot-session-logs/models';
import { DashboardSessionsArgs } from 'services/bot-sessions';
import { DateValue } from 'utils/dates';
import {
  dateRange,
  decoratorValueArray,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  rtkAdapterDynamicCount,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import { IBotSessionLog } from '__generated__/api';
export * from './models';

const dynamic = dynamicNamespace<IBotSessionLog>();

interface IUserActionKeyArgs {
  botID: string;
  dateRange: DateValue[];
}

class Service {
  getDashboardBotSessionLogs = async (input: DashboardSessionsArgs) => {
    return this.getDashboardBotSessionLogsFromApi(input);
  };
  private getDashboardBotSessionLogsFromApi = async (
    input: DashboardSessionsArgs,
  ): Promise<AxiosResponse<BotSessionLogUserActionKeyItem[]>> => {
    const response = await API.api.botSessionLogsGetAllDynamicList({
      ...this.makeDashboardBotSessionLogs(input),
    });

    return rtkAdapterDynamicToSource<BotSessionLogUserActionKeyItem>(response as unknown as any);
  };
  private makeDashboardBotSessionLogs(input: DashboardSessionsArgs) {
    const { tenantID, dateRange: dateRangeValue } = input;

    return {
      Filter: mergeFilters(
        dynamic.makeFilter('botSession.tenantID', tenantID, equals),
        dynamic.makeFilter(
          'userActionKey',
          [USER_ACTION_KEY.PRODUCT_ADD_TO_CART, USER_ACTION_KEY.PRODUCT_BUY],
          decoratorValueArray(equals),
        ),
        dynamic.makeFilter('botSession.createdDate', dateRangeValue, dateRange),
      ).join('&&'),
      Select: dynamic.select(
        'id, userActionKey, new { botSession.case.id, botSession.case.catalogName } as case, new { botSession.case.caseCategory.id, botSession.case.caseCategory.catalogName } as caseCategory ',
      ),
      Count: true,
    };
  }
}

export const ServiceBotSessionLogs = new Service();

export const apiBotSessionLogs = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getBotSessionLogsByBotSession: build.query({
      queryFn: async (botSessionID: string) => {
        const res = await API.api.botSessionLogsGetAllDynamicList({
          Filter: mergeFilters(dynamic.makeFilter('botSessionID', botSessionID, equals)).join('&&'),
          Select: dynamic.select(
            'id',
            'userActionType',
            'userActionKey',
            'userActionValue',
            'eventDate',
          ),
          Count: true,
          OrderBy: dynamic.orderBy('orderIndex', 'asc'),
        });

        return rtkAdapterDynamicToSource<GetBotSessionLogsByBotSessionItem>(res as unknown as any);
      },
    }),
    getBotSessionLogsUserActionProductBuyCount: build.query({
      queryFn: async (args: IUserActionKeyArgs) => {
        const res = await API.api.botSessionLogsGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter('botSession.botUserProfileID', args.botID, equals),
            dynamic.makeFilter('userActionKey', USER_ACTION_KEY.PRODUCT_BUY, equals),
            dynamic.makeFilter('createdDate', args.dateRange, dateRange),
          ).join('&&'),
          Select: dynamic.select('id'),
          Take: 1,
          Count: true,
        });
        return rtkAdapterDynamicCount<number>(res as unknown as any);
      },
    }),
    getBotSessionLogsUserActionProductAddToCardCount: build.query({
      queryFn: async (args: IUserActionKeyArgs) => {
        const res = await API.api.botSessionLogsGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter('botSession.botUserProfileID', args.botID, equals),
            dynamic.makeFilter('userActionKey', USER_ACTION_KEY.PRODUCT_ADD_TO_CART, equals),
            dynamic.makeFilter('createdDate', args.dateRange, dateRange),
          ).join('&&'),
          Select: dynamic.select('id'),
          Take: 1,
          Count: true,
        });
        return rtkAdapterDynamicCount<number>(res as unknown as any);
      },
    }),
    getLogs: build.query<
      BotSessionLogUserActionKeyItem[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionLogsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('botSession.tenantID', tenantID, equals),
              dynamic.makeFilter(
                'userActionKey',
                [USER_ACTION_KEY.PRODUCT_ADD_TO_CART, USER_ACTION_KEY.PRODUCT_BUY],
                decoratorValueArray(equals),
              ),
              dynamic.makeFilter('botSession.createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select(
              'id, userActionKey, new { botSession.case.id, botSession.case.catalogName } as case, new { botSession.case.caseCategory.id, botSession.case.caseCategory.catalogName } as caseCategory ',
            ),
            Count: true,
          });
          return rtkAdapterDynamicToSource<BotSessionLogUserActionKeyItem>(
            response as unknown as any,
          );
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
  }),
});
