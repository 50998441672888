import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { actionAccountGetUser, actionAccountLogout } from 'store/auth/actions';
import { ICurrentUserProfileResponse } from '../../__generated__/api';

interface InitState {
  isInit: boolean;
  isLoading: boolean;
  error: null | SerializedError;
  user: null | ICurrentUserProfileResponse;
  languageID: null | string;
  tenantID: null | string;
}

const initStateAccount = (): InitState => {
  return {
    isInit: false,
    isLoading: false,
    error: null,
    user: null,
    languageID: null,
    tenantID: null,
  };
};

const slice = createSlice({
  name: 'ACCOUNT',
  initialState: initStateAccount(),
  reducers: {
    actionAccountSetTenantID(state, action: PayloadAction<string>) {
      state.tenantID = action.payload;
    },
    actionAccountSetLanguageID(state, action: PayloadAction<string | null>) {
      state.languageID = action.payload;
    },
    actionAccountClear() {
      return initStateAccount();
    },
    actionAccountRefresh() {},
  },
  extraReducers: (builder) => {
    builder.addCase(actionAccountGetUser.pending, (state, action) => {
      const { isOnTheBackground } = action.meta.arg;
      if (!isOnTheBackground) {
        state.isLoading = true;
      }
      state.error = null;
    });
    builder.addCase(actionAccountGetUser.fulfilled, (state, action) => {
      const user = action.payload;

      const { isOnTheBackground } = action.meta.arg;

      if (!isOnTheBackground) {
        state.isLoading = false;
      }

      state.isInit = true;
      state.user = user;

      if (user.userProfileTenants && user.userProfileTenants.length === 1) {
        state.tenantID = String(user.userProfileTenants[0].tenantID);
      }
    });
    builder.addCase(actionAccountGetUser.rejected, (state, action) => {
      const { error } = action;
      const { isOnTheBackground } = action.meta.arg;
      if (!isOnTheBackground) {
        state.isLoading = false;
      }
      state.error = error;
      state.isInit = true;
    });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initStateAccount();
    });
  },
});
export const {
  actionAccountClear,
  actionAccountSetLanguageID,
  actionAccountSetTenantID,
  actionAccountRefresh,
} = slice.actions;
export const reducerAccount = slice.reducer;
