import {
  BotWelcomeMessageInputPatch,
  schemaBotWelcomeMessage,
} from 'services/bot-welcome-messages';
import { DateValue } from 'utils/dates';
import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import {
  IBotSessionTenantProductAction,
  IBotTheme,
  IBotUserProfile,
  ILanguage,
} from '__generated__/api';

export enum POSITION {
  TopLeft = 1,
  TopRight,
  MidLeft,
  MidRight,
  BottomLeft,
  BottomRight,
}
const schemaBotShared = yup.object({
  propertyCode: yup.string().nullable().notRequired(),
  icon: yup.mixed(),
  languageID: yup.string().nullable().required('rule-required'),
  isActive: yup.boolean(),
  botThemeID: yup.string().nullable().required('rule-required'),
  botZIndex: yup.number().nullable().integer('rule-integer').required('rule-required'),
  fromAge: yup
    .number()
    .nullable()
    .integer('rule-integer')
    .min(0, 'age-min')
    .max(121, 'age-max')
    .required('rule-required'),
  toAge: yup
    .number()
    .nullable()
    .integer('rule-integer')
    .min(0, 'age-min')
    .max(121, 'age-max')
    .required('rule-required')
    .test('should-be-more', 'rule-more-from-age', function (value) {
      const toAge = value;
      const fromAge = this.resolve(yup.ref('fromAge'));
      return Number(fromAge) < Number(toAge);
    }),
  iconPosition: yup.number().nullable().required('rule-required'),
  iconPositionVerticalMargin: yup.string().nullable().required('rule-required'),
  iconPositionHorizontalMargin: yup.string().nullable().required('rule-required'),
  useForVitAssist: yup.boolean(),
  useForPharmAssist: yup.boolean(),
  trackLog: yup.boolean(),
  displayGenderOther: yup.boolean(),
  displaySearch: yup.boolean(),
  displayProductsInfoHeader: yup.boolean(),
  displayProductMoreInfo: yup.boolean(),
  displayFilterByActiveIngredients: yup.boolean(),
  displayFilterByDosageForms: yup.boolean(),
});

export const schemaBotUserProfilePost = schemaBotShared.shape({
  title: yup.string().nullable().required('rule-required'),
});

const schemaContentModel = schemaBotWelcomeMessage.pick([
  'message',
  'dialogMaxWidth',
  'welcomeSubTitle',
  'productSubTitleLabel',
  'helpMeLabel',
  'termsAndPrivacyLabel',
  'disclaimer',
  'logo',
  'welcomeBanner',
  'termsAndConditionsHtmlContent',
  'privacyPolicyHtmlContent',
  'isActive',
]);

export const schemaBotUserProfilePatch = schemaBotShared.shape({
  contents: yup.array().of(schemaContentModel).required('rule-required'),
  title: yup.string().nullable().required('rule-required'),
  currency: yup.string().nullable().notRequired(),
  trackLog: yup.boolean(),
  internal: yup.boolean(),
  universal: yup.boolean(),
  displayRedFlagConfirm: yup.boolean(),
  displayLanguageSelector: yup.boolean(),
  displayRecommendationBasket: yup.boolean(),
  displayUnitPrice: yup.boolean(),
  applyAddToCart: yup.boolean(),
  addToCartCode: yup.string().nullable().notRequired(),

  filterMedicalConditionsTitleLabel: yup.string().nullable().notRequired().default(''),
  filterMedicalConditionsSubTitleLabel: yup.string().nullable().notRequired().default(''),
  filterDietaryPreferencesTitleLabel: yup.string().nullable().notRequired().default(''),
  filterDietaryPreferencesSubTitleLabel: yup.string().nullable().notRequired().default(''),
});

export interface BotUserProfileInputPost extends yup.InferType<typeof schemaBotUserProfilePost> {
  tenantID: string;
}
export interface BotUserProfileInputPatch extends yup.InferType<typeof schemaBotUserProfilePatch> {
  tenantID: string;
  appIdentityUserID: string;
  contents: BotWelcomeMessageInputPatch[];
}

export interface BotUserProfileSource extends Pick<IBotUserProfile, 'title' | 'icon' | 'isActive'> {
  id: string;
}

export interface IGridBotUserProfile
  extends Pick<
    IBotUserProfile,
    'title' | 'fromAge' | 'toAge' | 'internal' | 'propertyCode' | 'isActive' | 'icon'
  > {
  appIdentityUserID: string;
  botTheme: IBotTheme['title'];
  language: ILanguage['title'];
  numberOfSessions: number;
  numberOfScenarios: number;
}

export interface IGridBotUserProfileArgs {
  tenantID: string;
  isActive: null | boolean;
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
}

export interface GetBotResult extends BotUserProfileInputPatch {
  isHasSessions: boolean;
}

export interface GetBotAnalyticsInput {
  botID: string;
  dateRange: DateValue[];
}

export interface BotSessionsData {
  date: DateValue;
  age: number;
  isCompleted: boolean;
  gender: string;
}
export interface GetBotAnalyticsInfoResponse {
  activeBotCasesCount: number;
  botSessions: BotSessionsData[];
}
export interface GetBotAnalyticsProductsResponseItem
  extends Pick<IBotSessionTenantProductAction, 'actionType' | 'entryDate'> {}

export interface GetSessionsPerBotSessionItem {
  date: DateValue;
  catalogName: string;
  isCompleted: boolean;
}
export interface GetSessionsPerBotResponse {
  title: string;
  botSessions: GetSessionsPerBotSessionItem[];
}

export interface GetDashboardSessionsResponse {
  id: string;
  longitude: number;
  latitude: number;
  location: string;
  isSessionComplete: boolean;
}
