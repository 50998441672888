import { AxiosResponse } from 'axios';
import { dynamicNamespace } from 'utils/dynamic';
import { API, apiRtk, rtkAdapterDynamicToSource } from 'utils/service';
import { ILanguage, ILanguageDirection } from '__generated__/api';

const dynamic = dynamicNamespace<ILanguage>();

export interface Language
  extends Required<
    Pick<ILanguage, 'id' | 'icon' | 'isoCode' | 'title' | 'culture' | 'rank' | 'isActive'>
  > {
  id: string;
  direction: ILanguageDirection;
  culture: string;
  title: string;
}

export const apiLanguages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllLanguages: build.query<Language[], void>({
      queryFn: async () => {
        const response = await API.api.languagesGetAllDynamicList({
          Select: dynamic.select(
            'id',
            'icon',
            'isoCode',
            'title',
            'culture',
            'rank',
            'direction',
            'isActive',
          ),
          OrderBy: dynamic.orderBy('rank', 'asc'),
        });

        return rtkAdapterDynamicToSource(response) as AxiosResponse<Language[]>;
      },
    }),
    getAllMajorLanguages: build.query<Language[], void>({
      queryFn: async () => {
        const response = await API.api.languagesGetAllMajorDynamicList({
          Select: dynamic.select(
            'id',
            'icon',
            'isoCode',
            'title',
            'culture',
            'rank',
            'direction',
            'isActive',
          ),
          OrderBy: dynamic.orderBy('rank', 'asc'),
        });

        return rtkAdapterDynamicToSource(response) as AxiosResponse<Language[]>;
      },
    }),
    getDefaultLanguageID: build.query({
      queryFn: async (arg: void) => {
        return API.api.languagesGetDefaultLanguageList();
      },
    }),
  }),
});
