import React from 'react';

import clsx from 'clsx';

import { Typography } from '@mui/material';
import style from './index.module.scss';

const TITLE = 'Products Insights: Boost & Analyse';
const DESCRIPTION =
  'Elevate your Pharma products with our app. Obtain a comprehensive product list, in-depth analytics, customisable bots, and promotion tools. Analyse sessions, user preferences, and enhance the overall user experience.';
interface Props {
  className?: string;
  title?: string;
  description?: string;
  img?: string;
  children?: React.ReactNode;
}

export const SlickSliderSlide: React.FC<Props> = ({
  className,
  title = TITLE,
  description = DESCRIPTION,
  img,
  children,
}) => {
  return (
    <div className={clsx(style.root, className)}>
      <div className={style.top}>
        <img src={img} className={style.image} alt="" />
        <div className={style.icon}>{children}</div>
      </div>
      <Typography
        variant={'h4'}
        component={'h3'}
        color={'primary.contrastText'}
        mt={'3.1rem'}
        pl={'1.6rem'}
        pr={'1.6rem'}
      >
        {title}
      </Typography>
      <Typography
        color={'primary.contrastText'}
        mt={'1.2rem'}
        sx={{ opacity: 0.8 }}
        pl={'1.6rem'}
        pr={'1.6rem'}
      >
        {description}
      </Typography>
    </div>
  );
};
