import { IBotSessionLog, ICase, ICaseCategory } from '__generated__/api';

export enum BOT_LOG_USER_ACTION_KEY {
  CASE_MSG = 'case-msg',
  FIND_MY_OTC = 'find-my-otc',
  REFER_MSG = 'refer-msg',
  SELECT_GENDER = 'select-gender',
  SELECT_AGE = 'select-age',
  SELECT_CASE = 'select-case',
  CASE_DECISION = 'case-decision',
  CASE_DECISION_CHOSEN = 'case-decision-chosen',
  DOSAGE_FORM = 'dosage-form',
  DOSAGE_FORM_SELECTED = 'dosage-form-selected',
  DOSAGE_FORM_UNSELECTED = 'dosage-form-unselected',
  PRODUCTS = 'products',
}
export interface BotSessionLogItem extends Omit<IBotSessionLog, 'userActionKey'> {
  userActionKey: BOT_LOG_USER_ACTION_KEY;
}

export type GetBotSessionLogsByBotSessionItem = Pick<
  BotSessionLogItem,
  'id' | 'userActionType' | 'userActionKey' | 'userActionValue' | 'eventDate'
>;

export enum USER_ACTION_KEY {
  PRODUCT_BUY = 'product-buy',
  PRODUCT_ADD_TO_CART = 'product-add-to-cart',
}

export interface BotSessionLogUserActionKeyItem extends Omit<IBotSessionLog, 'userActionKey'> {
  caseCategory: Pick<ICaseCategory, 'id' | 'catalogName'>;
  case: Pick<ICase, 'catalogName' | 'id'>;
  userActionKey: USER_ACTION_KEY;
}
