import { createSelector } from '@reduxjs/toolkit';
import { apiLabels } from 'services/labels';
import { createMap } from 'utils/other';
import { AppState } from '../index';

const selectState = (state: AppState) => state.account;
export const selectAccountUser = createSelector(selectState, ({ user }) => {
  return user;
});

export const selectAccountTenantID = createSelector(selectState, ({ tenantID }) => {
  return tenantID;
});
export const selectAccountTenants = createSelector(selectState, ({ user }) => {
  return user?.userProfileTenants || [];
});
export const selectAccountTenant = createSelector(
  selectAccountTenantID,
  selectAccountTenants,
  (tenantID, tenants) => {
    return tenants.find((item) => item.tenantID === tenantID);
  },
);

export const selectAccountState = createSelector(selectState, ({ isLoading, isInit, error }) => {
  return { isLoading, isInit, error };
});

export const selectAccountLanguageID = createSelector(selectState, ({ languageID }) => {
  return languageID;
});
const selectRtkLabels = createSelector(
  (state: AppState) => state,
  selectAccountLanguageID,
  (state, languageID) => {
    return apiLabels.endpoints.getAppLabels.select({ languageID: languageID || '' })(state);
  },
);
export const selectAccountLabels = createSelector(selectRtkLabels, (result) => {
  return result.data || [];
});

export const selectAccountLabelsMap = createSelector(selectAccountLabels, (labels) => {
  return createMap(labels, 'labelKey') || {};
});
