import { Box, BoxProps, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface Classes {
  circularProgress: string;
}

interface Props extends BoxProps {
  classes?: Partial<Classes>;
  size?: string | number;
}

export const AppProgressCircular: React.FC<Props> = ({ className, size, classes, ...rest }) => {
  return (
    <Box className={clsx(style.root, className)} {...rest}>
      <CircularProgress size={size} className={classes?.circularProgress} />
    </Box>
  );
};
