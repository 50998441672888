import { dynamicNamespace } from 'utils/dynamic';
import { API, apiRtk } from 'utils/service';
import { IPharmaCompany } from '__generated__/api';
import { IPharmaCompanies } from './models';
const dynamic = dynamicNamespace<IPharmaCompany>();

export * from './models';
export const apiPharmaCompanies = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourcePharmaCompanies: build.query<IPharmaCompanies[], void>({
      queryFn: async () => {
        try {
          const { data } = await API.api.pharmaCompaniesGetAllDynamicList({
            Select: dynamic.select('id', 'companyName as title'),
            OrderBy: dynamic.orderBy('companyName', 'asc'),
          });
          return { data: data?.items || [] } as { data: IPharmaCompanies[] };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
