import {
  contains,
  decoratorDate,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  lessOrEquals,
  makeFilter,
  mergeFilters,
  moreOrEquals,
  orderBy,
  select,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicItem,
} from 'utils/service';
import { IPharmaCompanyOrder } from '__generated__/api';
import {
  IGridPharmaCompanyOrderArgs,
  IGridPharmaCompanyOrderResponse,
  IPharmaCompanyOrderInfoResponse,
  ProductCompanyOrderGet,
  ProductCompanyOrderInputPatch,
} from './models';

const REVALIDATE_KEY = 'PharmaCompanyOrders' as const;

const dynamic = dynamicNamespace<IPharmaCompanyOrder>();

export * from './models';

export const apiPharmaCompanyOrders = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridPharmaCompanyOrders: build.query<
      IGridPharmaCompanyOrderResponse,
      IGridPharmaCompanyOrderArgs
    >({
      // @ts-ignore
      queryFn: ({ tenantID, search, date, countryID, isActive, take, skip, orderBy: order }) =>
        API.api.pharmaCompanyOrdersGetAllDynamicList({
          Filter: mergeFilters(
            `pharmaCompanyOrderTenants.Select(s => s.TenantID).Contains("${tenantID}")`,
            makeFilter<IPharmaCompanyOrder>(
              ['pharmaCompany.companyName', 'orderNumber', 'orderStatus.title'],
              search,
              decoratorIsNotNullable(contains),
            ),
            makeFilter<IPharmaCompanyOrder>('countryID', countryID, decoratorIsNotNullable(equals)),
            makeFilter<IPharmaCompanyOrder>('isActive', isActive, decoratorIsNotNullable(equals)),
            makeFilter(
              'startDate',
              date[0],
              decoratorIsNotNullable(decoratorDate(lessOrEquals, 'yyyy,MM,dd,00,00,00')),
            ),
            makeFilter(
              'endDate',
              date[1],
              decoratorIsNotNullable(decoratorDate(moreOrEquals, 'yyyy,MM,dd,23,59,59')),
            ),
          ).join('&&'),
          Select: select<IPharmaCompanyOrder>(
            'id',
            'orderNumber',
            'orderStatus.title as orderStatusTitle',
            'pharmaCompany.companyName as pharmaCompanyName',
            'country.countryName as countryName',
            'startDate',
            'endDate',
            'pharmaCompanyOrderDetails.Count as numberOfProducts',
            'totalPrice',
            'botSessionTenantProductActions.Sum(s => s.PriceForAction) as totalBudgetUsed',
            'maxProducts',
            'country.flag as countryFlag',
            'isActive',
          ),
          OrderBy: orderBy(order.field, order.order),
          Take: take,
          Skip: skip,
          Count: true,
        }),
      providesTags: [{ type: 'PharmaCompanyOrderHelpers' }],
    }),
    getPharmaCompanyOrderInfo: build.query<
      IPharmaCompanyOrderInfoResponse,
      { id: string; tenantID: string }
    >({
      queryFn: async ({ id, tenantID }) => {
        const response = await API.api.pharmaCompanyOrdersGetAllDynamicList({
          Select: select<IPharmaCompanyOrder>(
            'id',
            'pharmaCompany.companyName as pharmaCompanyName',
            'country.countryName as countryName',
            'startDate',
            'endDate',
            'pharmaCompanyOrderDetails.Count as numberOfProducts',
            'pricePerDay',
            'totalPrice',
            'botSessionTenantProductActions.Sum(s => s.PriceForAction) as totalBudgetUsed',
            'maxProducts',
            'orderNumber',
          ),
          Filter: mergeFilters(
            dynamic.makeFilter('id', id, equals),
            `pharmaCompanyOrderTenants.Select(s => s.TenantID).Contains("${tenantID}")`,
          ).join('&&'),
          Take: 1,
        });

        return rtkAdapterDynamicItem<IPharmaCompanyOrderInfoResponse>(response as unknown as any);
      },
      providesTags: (result, error, { id }) => [{ type: 'PharmaCompanyOrderHelpers', id }],
    }),
    getPharmaCompanyOrder: build.query<ProductCompanyOrderGet, { id: string }>({
      queryFn: async ({ id }) => {
        const { data } = await getByIDRequestHelper({
          apiFunc: API.api.pharmaCompanyOrdersGetAllDynamicList,
          value: id,
          params: {
            Select: dynamic.select(
              'id',
              'orderNumber',
              'startDate',
              'endDate',
              'orderStatusID',
              'countryID',
              'pharmaCompanyID',
              'pricePerDay',
              'totalPrice',
              'maxProducts',
              'isActive',
              'pharmaCompany',
              'pharmaCompanyOrderTenants.Select(k => k.tenantID) as tenantIDs',
            ),
          },
        });

        return { data } as { data: ProductCompanyOrderGet };
      },
      providesTags: (_, __, { id }) => [{ type: REVALIDATE_KEY, id }],
    }),
    postPharmaCompanyOrder: build.mutation({
      queryFn: async ({ tenantID, ...rest }) => {
        try {
          const result = await API.api.pharmaCompanyOrdersCreateCreate(rest);

          await API.api.pharmaCompanyOrderTenantsCreateCreate({
            tenantID,
            pharmaCompanyOrderID: result.data.id,
          });
          return result;
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: 'PharmaCompanyOrderHelpers' }],
    }),
    patchPharmaCompanyOrder: build.mutation<
      string,
      Omit<ProductCompanyOrderInputPatch, 'pharmaCompany'>
    >({
      queryFn: async ({ tenantIDs, ...rest }) => {
        try {
          const [patchResult] = await Promise.all([
            API.api.pharmaCompanyOrdersPatchPartialUpdate(...makePatchArgs(rest)),
            API.api.pharmaCompanyOrderTenantsCreateBulkCreate({
              pharmaCompanyOrderID: rest.id,
              tenantIDs: tenantIDs.filter(Boolean) as string[],
            }),
          ]);

          return patchResult;
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, { id }) => [
        { type: REVALIDATE_KEY, id },
        { type: 'PharmaCompanyOrderHelpers' },
      ],
    }),
    deletePharmaCompanyOrder: build.mutation<IPharmaCompanyOrder, Pick<IPharmaCompanyOrder, 'id'>>({
      queryFn: (data) => API.api.pharmaCompanyOrdersDeleteDelete(String(data?.id)),
      invalidatesTags: [{ type: 'PharmaCompanyOrderHelpers' }],
    }),
  }),
});
