import { Check } from '@mui/icons-material';
import { Breakpoint, Button, Dialog, Theme, Tooltip, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { AppDialogAction, AppDialogContent } from 'components/app-dialog/components';
import { AppProgressCircular } from 'components/app-progress-circular';
import { DialogHeader } from 'components/dialog-header';
import { useTranslate } from 'hooks';
import React, { FC, memo, useCallback } from 'react';
import style from './index.module.scss';

interface DefaultButtonsProps {
  onClose: () => void;
}

const DefaultButtons: FC<DefaultButtonsProps> = ({ onClose }) => {
  const { tp } = useTranslate();

  return (
    <Tooltip title={tp('agree-tooltip')}>
      <Button
        color={'primary'}
        variant={'contained'}
        size={'medium'}
        onClick={onClose}
        startIcon={<Check />}
      >
        {tp('agree')}
      </Button>
    </Tooltip>
  );
};

interface Classes {
  dialogAction: string;
  dialogContent: string;
}

interface Props {
  isLoading: boolean;
  title?: string | null;
  content?: string | null;
  onClose: () => void;
  Buttons?: React.ReactNode;
  maxWidth?: false | Breakpoint | undefined;
  classes?: Partial<Classes>;
}

export const DialogAgree = memo<Props>(
  ({ isLoading, title, content, onClose, Buttons, maxWidth = 'lg', classes }) => {
    const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

    const renderButtons = useCallback(() => {
      if (Buttons) {
        return Buttons;
      }

      return <DefaultButtons onClose={onClose} />;
    }, [onClose, Buttons]);

    return (
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth={maxWidth}
        fullWidth={!isMobile}
        fullScreen={isMobile}
      >
        <DialogHeader title={title} onClose={onClose} />
        <AppDialogContent className={classes?.dialogContent}>
          <div dangerouslySetInnerHTML={{ __html: content || '' }} />
        </AppDialogContent>
        <AppDialogAction className={clsx(style.dialogAction, classes?.dialogAction)}>
          {renderButtons()}
        </AppDialogAction>
        {isLoading && <AppProgressCircular />}
      </Dialog>
    );
  },
);
