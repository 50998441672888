import { useCallback } from 'react';

const defAfter = () => {};
export const useDecorator = <T extends (...args: any[]) => any>(
  cb: T,
  after: (...args: any[]) => void = defAfter,
) => {
  return useCallback(
    (...args: any[]) => {
      const result = cb(...args);
      after(...args);
      return result;
    },
    [cb, after],
  );
};
