import { Box, Paper } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import { useLanguage } from 'hooks/use-language';
import imgSlide1 from 'images/img-slide-1.jpg';
import imgSlide2 from 'images/img-slide-2.jpg';
import imgSlide3 from 'images/img-slide-3.jpg';
import imgSliderIcon1 from 'images/img-slider-icon-1.png';
import { memo, useMemo } from 'react';
import { useOutlet } from 'react-router-dom';
import { apiPages } from 'services/pages';
import { Footer, Header, SlickSlider, SlickSliderSlide } from './components';
import style from './index.module.scss';

const calc = (x: number, y: number) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const icon = (x: number, y: number) => `translate3d(${x / -50}px,${y / -30}px,0)`;

export const LoginLayout = memo(() => {
  const outlet = useOutlet();

  const { languageID } = useLanguage();
  const { data } = apiPages.useGetPageLandingQuery(languageID || '', { skip: !languageID });

  const pageTitle = useMemo(() => {
    return data?.searchEngineOptimization?.metaTitle || '';
  }, [data]);

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <Box
      className={style.wrap}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <Header pageTitle={pageTitle} />
      <div className={style.left}>
        <Paper className={style.paper}>
          <div>{outlet}</div>
        </Paper>
      </div>
      <Box className={style.right}>
        <div className={style.slider}>
          <SlickSlider>
            <SlickSliderSlide
              img={imgSlide1}
              title={'Enhancing Product Visibility: Promotion Board and Strategic Positioning'}
              description={
                'Our Bidding system functions as a Promotion board, allowing customers to view product simulations presented to bot users, including those of their company and also competitors. Customers are making decisions on whether and how to promote their products in specific scenarios, selecting their preferred position - it brings higher odds of users choosing and purchasing products in top positions.'
              }
            >
              <animated.div className={style.bgL1} style={{ transform: props.xy.to(icon) }}>
                <img src={imgSliderIcon1} alt="" />
              </animated.div>
            </SlickSliderSlide>
            <SlickSliderSlide
              img={imgSlide2}
              title={'Unveiling User Engagement: Sessions'}
              description={
                "We are introducing our 'Sessions' service for In-depth Bot Interaction Insights. It provides an exhaustive log of bot interactions, including user demographics, progression duration through each interaction step, displayed products, engagement actions (views, clicks, cart additions), preferences, and other relevant details, enabling insightful analysis and optimisation."
              }
            >
              <animated.div className={style.bgL1} style={{ transform: props.xy.to(icon) }}>
                <img src={imgSliderIcon1} alt="" />
              </animated.div>
            </SlickSliderSlide>
            <SlickSliderSlide
              img={imgSlide3}
              title={'Products Insights: Boost & Analyse'}
              description={
                'Elevate your Pharma products with our app. Obtain a comprehensive product list, in-depth analytics, customisable bots, and promotion tools. Analyse sessions, user preferences, and enhance the overall user experience.'
              }
            >
              <animated.div className={style.bgL1} style={{ transform: props.xy.to(icon) }}>
                <img src={imgSliderIcon1} alt="" />
              </animated.div>
            </SlickSliderSlide>
          </SlickSlider>
        </div>
      </Box>
      <Footer />
    </Box>
  );
});
