import React from 'react';

import { DialogAgree } from 'components/app-dialog/dialog-agree';
import { useLanguage } from 'hooks/use-language';
import { apiPages } from 'services/pages';

interface Props {
  onClose: () => void;
}

export const ContainerDialogTerms: React.FC<Props> = ({ onClose }) => {
  const { languageID } = useLanguage();
  const { data, isLoading } = apiPages.useGetPageTermsQuery(languageID || '', {
    skip: !languageID,
  });

  return (
    <DialogAgree
      isLoading={isLoading}
      title={data?.title}
      content={data?.htmlContent}
      onClose={onClose}
    />
  );
};
