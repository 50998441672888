import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endOfMonth, startOfMonth } from 'date-fns';
import { BotSessionDosageForm } from 'services/bot-session-dosage-forms';
import { BotSessionLogUserActionKeyItem } from 'services/bot-session-logs';
import { BotSessionProductItem } from 'services/bot-session-products';
import { BotSessionAnalyticsItem } from 'services/bot-sessions';
import {
  actionGetDashboardBotSessionLogs,
  actionGetDashboardDosageFormSession,
  actionGetDashboardSessionAnalytics,
  actionGetDashboardSessionProducts,
  actionGetNumberOfBots,
  actionGetNumberOfIgnoredProducts,
  actionGetNumberOfProducts,
} from './actions';

export interface DashboardSessionsArgs {
  dateRange: Date[];
  tenantID: string;
}

export interface IDashboardCategoryOption {
  id: string;
  title: string;
}

interface InitStateFilters {
  dateRange: Date[];
  category: IDashboardCategoryOption | null;
}

interface InitState extends InitStateFilters {
  numberOfBotsCount: number;
  numberOfBotsLoading: boolean;

  numberOfProductsCount: number;
  numberOfProductsLoading: boolean;

  numberOfIgnoredProductsCount: number;
  numberOfIgnoredProductsLoading: boolean;

  sessionAnalytics: BotSessionAnalyticsItem[];
  sessionAnalyticsLoading: boolean;

  sessionDosageForm: BotSessionDosageForm[];
  sessionDosageFormLoading: boolean;

  sessionProducts: BotSessionProductItem[];
  sessionProductsLoading: boolean;

  sessionLogs: BotSessionLogUserActionKeyItem[];
  sessionLogsLoading: boolean;
}

export const DASHBOARD_CATEGORY_ALL_OPTION = 'all';

const initState = (): InitState => {
  return {
    dateRange: [startOfMonth(new Date()), endOfMonth(new Date())],
    category: { id: DASHBOARD_CATEGORY_ALL_OPTION, title: DASHBOARD_CATEGORY_ALL_OPTION },

    numberOfBotsCount: 0,
    numberOfBotsLoading: false,

    numberOfProductsCount: 0,
    numberOfProductsLoading: false,

    numberOfIgnoredProductsCount: 0,
    numberOfIgnoredProductsLoading: false,

    sessionAnalytics: [],
    sessionAnalyticsLoading: false,

    sessionDosageForm: [],
    sessionDosageFormLoading: false,

    sessionProducts: [],
    sessionProductsLoading: false,

    sessionLogs: [],
    sessionLogsLoading: false,
  };
};

const slice = createSlice({
  name: 'DASHBOARD',
  initialState: initState(),
  reducers: {
    actionDashboardSetDateRange(state, action: PayloadAction<Date[]>) {
      state.dateRange = action.payload;
    },
    actionDashboardSetCategory(state, action) {
      state.category = action.payload;
    },
    actionDashboardInit(state) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionGetNumberOfBots.pending, (state, action) => {
        state.numberOfBotsLoading = true;
      })
      .addCase(actionGetNumberOfBots.fulfilled, (state, action) => {
        state.numberOfBotsLoading = false;
        state.numberOfBotsCount = action.payload;
      })
      .addCase(actionGetNumberOfBots.rejected, (state, action) => {
        state.numberOfBotsLoading = false;
      });

    builder
      .addCase(actionGetNumberOfProducts.pending, (state, action) => {
        state.numberOfProductsLoading = true;
      })
      .addCase(actionGetNumberOfProducts.fulfilled, (state, action) => {
        state.numberOfProductsLoading = false;
        state.numberOfProductsCount = action.payload;
      })
      .addCase(actionGetNumberOfProducts.rejected, (state, action) => {
        state.numberOfProductsLoading = false;
      });

    builder
      .addCase(actionGetNumberOfIgnoredProducts.pending, (state, action) => {
        state.numberOfIgnoredProductsLoading = true;
      })
      .addCase(actionGetNumberOfIgnoredProducts.fulfilled, (state, action) => {
        state.numberOfIgnoredProductsLoading = false;
        state.numberOfIgnoredProductsCount = action.payload;
      })
      .addCase(actionGetNumberOfIgnoredProducts.rejected, (state, action) => {
        state.numberOfIgnoredProductsLoading = false;
      });

    builder
      .addCase(actionGetDashboardSessionAnalytics.pending, (state, action) => {
        state.sessionAnalyticsLoading = true;
      })
      .addCase(actionGetDashboardSessionAnalytics.fulfilled, (state, action) => {
        state.sessionAnalyticsLoading = false;
        state.sessionAnalytics = action.payload;
      })
      .addCase(actionGetDashboardSessionAnalytics.rejected, (state, action) => {
        state.sessionAnalyticsLoading = false;
      });

    builder
      .addCase(actionGetDashboardDosageFormSession.pending, (state, action) => {
        state.sessionDosageFormLoading = true;
      })
      .addCase(actionGetDashboardDosageFormSession.fulfilled, (state, action) => {
        state.sessionDosageFormLoading = false;
        state.sessionDosageForm = action.payload;
      })
      .addCase(actionGetDashboardDosageFormSession.rejected, (state, action) => {
        state.sessionDosageFormLoading = false;
      });

    builder
      .addCase(actionGetDashboardSessionProducts.pending, (state, action) => {
        state.sessionProductsLoading = true;
      })
      .addCase(actionGetDashboardSessionProducts.fulfilled, (state, action) => {
        state.sessionProductsLoading = false;
        state.sessionProducts = action.payload;
      })
      .addCase(actionGetDashboardSessionProducts.rejected, (state, action) => {
        state.sessionProductsLoading = false;
      });

    builder
      .addCase(actionGetDashboardBotSessionLogs.pending, (state, action) => {
        state.sessionLogsLoading = true;
      })
      .addCase(actionGetDashboardBotSessionLogs.fulfilled, (state, action) => {
        state.sessionLogsLoading = false;
        state.sessionLogs = action.payload;
      })
      .addCase(actionGetDashboardBotSessionLogs.rejected, (state, action) => {
        state.sessionLogsLoading = false;
      });
  },
});
export const { actionDashboardSetDateRange, actionDashboardInit, actionDashboardSetCategory } =
  slice.actions;
export const reducerDashboard = slice.reducer;
