import { createTheme, ThemeOptions } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import variables from 'styles/config.scss';

const appBreakpoints = {
  values: {
    xs: Number(variables.xs),
    sm: Number(variables.sm),
    md: Number(variables.md),
    lg: Number(variables.lg),
    xl: Number(variables.xl),
    xxl: Number(variables.xxl),
    xxxl: Number(variables.xxxl),
  },
};

const breakpoints = createBreakpoints({ ...appBreakpoints });
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string, lightColor?: string, darkColor?: string) =>
  augmentColor({ color: { main: mainColor, light: lightColor, dark: darkColor } });

export const AppThemeOptions: ThemeOptions = {
  breakpoints: breakpoints,
  spacing: 10,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontFamily: variables.fontMain,
    h1: {
      fontSize: '3.6rem',
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h3: {
      fontSize: '2.4rem',
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.4,
    },
    h5: {
      fontSize: '1.8rem',
      fontWeight: 500,
      lineHeight: 1.33,
    },
    h6: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontWeight: 500,
      lineHeight: 1.43,
    },
    body1: {
      fontSize: '1.4rem',
      lineHeight: 1.43,
    },
    body2: {
      fontSize: '1.2rem',
      lineHeight: 1.33,
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    caption: {
      fontSize: '1.1rem',
      lineHeight: 1.2,
    },
  },

  palette: {
    background: {
      default: variables.colorPageBackground,
    },
    text: {
      primary: variables.colorTextPrimary,
      secondary: variables.colorTextSecondary,
    },
    primary: {
      main: variables.colorPrimary,
      contrastText: variables.colorWhite,
    },
    secondary: {
      main: variables.colorSecondary,
      contrastText: variables.colorWhite,
    },
    error: {
      main: variables.colorError,
      contrastText: variables.colorWhite,
    },
    success: {
      main: variables.colorSuccess,
      contrastText: variables.colorWhite,
    },
    warning: {
      main: variables.colorWarning,
      contrastText: variables.colorWhite,
    },
    info: {
      light: variables.colorInfoLight,
      main: variables.colorInfo,
      contrastText: variables.colorWhite,
    },

    //tone.main divider, tone.light background, tone.dark
    tone: createColor(
      variables.colorDivider,
      variables.colorBackground,
      variables.colorPageBackground,
    ),

    tertiary: createColor(variables.colorTertiary),

    silver: createColor(variables.colorSilver),
    white: createColor(variables.colorWhite),
  },

  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: variables.colorDivider,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderColor: variables.colorBorder,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          [breakpoints.down('md')]: {
            minHeight: '3.8rem',
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: '6rem',
          color: variables.colorTextPrimary,
          [breakpoints.down('md')]: {
            padding: ' 0.5rem 1.6rem',
            minHeight: '3.8rem',
            fontSize: '1.2rem',
            minWidth: '7.2rem',
          },
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderColor: variables.colorBorder,
          borderWidth: 2,
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          lineHeight: 1,
          minHeight: '3.6rem',
          paddingTop: '0.3rem',
          paddingBottom: '0.3rem',
          fontWeight: 500,
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
        contained: {
          '&.Mui-disabled': {
            background: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
        sizeSmall: {
          fontSize: '1.1rem',
          minHeight: '2.4rem',
          padding: '0.2rem 0.8rem',
          fontWeight: 600,
        },
        sizeLarge: {
          fontSize: '1.8rem',
          minHeight: '5.6rem',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 'inherit',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          padding: '0.6rem',
        },
      },
    },

    // DIALOG
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: ' 1.5rem 2rem',
          display: 'flex',
          [breakpoints.down('md')]: {
            padding: ' 1rem 1.6rem',
          },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '2rem 2rem 0',
          [breakpoints.down('md')]: {
            padding: '1rem 1.6rem 0',
          },
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '2rem',
          [breakpoints.down('md')]: {
            padding: ' 1rem 1.6rem',
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowY: 'inherit',
          borderRadius: 4,
          overflow: 'hidden',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
        },
        paperFullScreen: {
          borderRadius: '0',
        },
        paperScrollPaper: {
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            color: variables.colorTextPrimary,
            textFillColor: variables.colorTextPrimary,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        message: {
          alignSelf: 'center',
        },
      },
    },

    // from new
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '1.6rem',
          paddingRight: '1.6rem',
          [breakpoints.up('sm')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
          [breakpoints.up('md')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
          [breakpoints.up('lg')]: {
            paddingLeft: '3rem',
            paddingRight: '3rem',
          },
        },
      },
    },

    MuiPaginationItem: {
      defaultProps: {
        color: 'primary',
        shape: 'rounded',
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          lineHeight: 1.2,
        },
        outlinedPrimary: {
          '&.Mui-selected': {
            backgroundColor: variables.colorPrimary,
            color: variables.colorWhite,
            cursor: 'default',
            '&:hover': {
              backgroundColor: variables.colorPrimary,
              color: variables.colorWhite,
            },
          },
          '&:hover': {
            backgroundColor: variables.colorPrimary,
            color: variables.colorWhite,
          },
        },
        ellipsis: {
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'inherit',
          },
        },
        icon: {
          fontSize: '2rem',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: '0.2rem',
          paddingBottom: '0.2rem',
          lineHeight: 1,
          fontSize: '1.1rem',
          letterSpacing: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1.6rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          marginRight: 0,
          color: variables.colorSilver,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeMedium: {
          height: 'auto',
          fontSize: '1.2rem',
        },
        deleteIcon: {
          fontSize: '1.8rem',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
  },
};
