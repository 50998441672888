import { AxiosResponse } from 'axios';
import { IGridTenantProductsResponse, ITenantProductInfo } from 'services/tenant-products';
import * as dynamic from 'utils/dynamic';
import { contains, decoratorIsNotNullable, equals, mergeFilters } from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicCount,
  rtkAdapterDynamicItem,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ITenantIgnoreProduct } from '__generated__/api';
import { IgnoreProductInput, IGridIgnoredProductArgs } from './models';

const REVALIDATE_KEY = 'TenantIgnoredProducts' as const;

export * from './models';
class Service {
  async getNumberOfIgnoredProducts(args: { tenantID: string }) {
    const { tenantID } = args;
    const response = await API.api.tenantIgnoreProductsGetAllDynamicList({
      Filter: mergeFilters(
        dynamic.makeFilter('tenantID', tenantID, equals),
        dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
      ).join('&&'),
      Select: dynamic.select('id'),
      Count: true,
    });

    return rtkAdapterDynamicCount<number>(response as unknown as any);
  }
}

export const ServiceTenantIgnoredProducts = new Service();
export const apiTenantIgnoredProducts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridTenantIgnoredProducts: build.query({
      queryFn: (params: IGridIgnoredProductArgs) =>
        API.api.tenantIgnoreProductsGetAllDynamicList({
          Select: dynamic.select('id', 'serialNumber', 'productName'),
          Filter: mergeFilters(
            dynamic.makeFilter('tenantID', params.tenantID, decoratorIsNotNullable(equals)),
            dynamic.makeFilter(['serialNumber', 'productName'], params.search, contains),
          ).join('&&'),
          OrderBy: dynamic.orderBy(params.orderBy.field, params.orderBy.order),
          Take: params.take,
          Skip: params.skip,
          Count: true,
        }) as Promise<AxiosResponse<IGridTenantProductsResponse>>,
      providesTags: [{ type: REVALIDATE_KEY, id: 'grid' }],
    }),
    getTenantIgnoredProduct: build.query({
      queryFn: async (tenantProductID: string) => {
        const result = await API.api
          .tenantProductsGetAllDynamicList({
            Select: dynamic.select('id', 'serialNumber', 'productName'),
            Filter: dynamic.makeFilter('id', tenantProductID, equals),
            Take: 1,
          })
          .then(rtkAdapterDynamicItem);

        return result as unknown as { data: ITenantProductInfo };
      },
    }),
    postTenantIgnoredProduct: build.mutation({
      queryFn: async (input: IgnoreProductInput) => {
        return API.api.tenantIgnoreProductsCreateCreate(input);
      },
      invalidatesTags: (_, __, { tenantID }) => [{ type: REVALIDATE_KEY, id: tenantID as string }],
    }),
    patchTenantIgnoredProduct: build.mutation({
      queryFn: async (input: PatchPartial<IgnoreProductInput, 'id'>) => {
        return API.api.tenantIgnoreProductsPatchPartialUpdate(...makePatchArgs(input));
      },
      invalidatesTags: (_, __, { tenantID }) => [{ type: REVALIDATE_KEY, id: tenantID as string }],
    }),
    deleteTenantIgnoredProduct: build.mutation({
      queryFn: (input: Pick<ITenantIgnoreProduct, 'id' | 'tenantID'>) => {
        return API.api.tenantIgnoreProductsDeleteDelete(String(input.id));
      },
      invalidatesTags: (_, __, { tenantID }) => [{ type: REVALIDATE_KEY, id: tenantID as string }],
    }),
  }),
});
