import { patchFiles } from 'modules/dynamic-service';
import { dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import { makeUniqueFileName } from 'utils/file-uploader';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
} from 'utils/service';
import { FixServerObject } from 'utils/types';
import { IBotWelcomeMessage } from '__generated__/api';
import { BotWelcomeMessageGet, BotWelcomeMessageInputPatch } from './models';

const REVALIDATE_KEY = 'BotWelcomeMessages' as const;

const dynamic = dynamicNamespace<IBotWelcomeMessage>();

export * from './models';

export const apiBotWelcomeMessages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceBotWelcomeMessages: build.query({
      queryFn: async (botID: string) => {
        const response = await API.api.botWelcomeMessagesGetAllDynamicList({
          Filter: dynamic.makeFilter('botUserProfile.appIdentityUserID', botID, equals),
        });

        return rtkAdapterDynamicToSource<FixServerObject<IBotWelcomeMessage, 'id'>>(
          response as unknown as any,
        );
      },
      providesTags: (_, __, botID) => [{ type: REVALIDATE_KEY, id: `SOURCE-${botID}` }],
    }),

    getBotWelcomeMessage: build.query({
      queryFn: async (id: string) => {
        const response = await API.api.botWelcomeMessagesGetAllDynamicList({
          Select: dynamic.select(
            'id',
            'languageID',
            'message',
            'dialogMaxWidth',
            'welcomeSubTitle',
            'productSubTitleLabel',
            'helpMeLabel',
            'termsAndPrivacyLabel',
            'disclaimer',
            'isActive',
            'logo',
            'welcomeBanner',
            'termsAndConditionsHtmlContent',
            'privacyPolicyHtmlContent',
          ),
          Filter: mergeFilters(dynamic.makeFilter('id', id, equals)).join('&&'),
          Take: 1,
        });

        return rtkAdapterDynamicItem<BotWelcomeMessageGet>(response as unknown as any);
      },
      providesTags: (_, __, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    patchBotWelcomeMessage: build.mutation({
      queryFn: async ({
        dataNew,
        dataOld,
      }: {
        dataNew: BotWelcomeMessageInputPatch;
        dataOld: BotWelcomeMessageInputPatch;
      }) => {
        const [patchData] = await patchFiles(dataNew, dataOld, {
          logo: makeUniqueFileName('logo'),
          welcomeBanner: makeUniqueFileName('welcomeBanner'),
        });
        return API.api.botWelcomeMessagesPatchPartialUpdate(...makePatchArgs(patchData));
      },
      invalidatesTags: (_, __, { dataNew: { id } }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
