import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Button, ListItemButton, Popover, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useAppDispatch, useCurrentUser, useTranslate } from 'hooks';
import { useTenant } from 'hooks/use-tenant';
import React, { memo, useCallback } from 'react';
import { actionAccountLogout } from 'store/auth';
import { composeFunctions } from 'utils/other';
import style from './index.module.scss';

interface Props {
  isOpen?: boolean;
  isHideTenants?: boolean;
}

export const ProfileNavPanel = memo<Props>(({ isOpen, isHideTenants = false }) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const { userName } = useCurrentUser();
  const { tenant, tenants, tenantID, setTenant } = useTenant();
  const factorySetTenant = useCallback(
    (tenID: string) => {
      return () => setTenant(tenID);
    },
    [setTenant],
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = useCallback(() => {
    dispatch(actionAccountLogout());
  }, [dispatch]);

  const { spacing } = useTheme();

  return (
    <>
      <Button className={clsx(style.root, isOpen && style.rootOpen)} onClick={handleClick}>
        <Avatar
          src={tenant?.logo ?? undefined}
          sx={{ width: spacing(3.6), height: spacing(3.6) }}
        />
        <Typography variant={'subtitle2'} noWrap className={style.name}>
          {userName}
        </Typography>
        {isOpen && <KeyboardArrowDownIcon className={style.arrow} />}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!isHideTenants &&
          tenants.map((item) => {
            return (
              <ListItemButton
                key={item.tenantID}
                className={style.btnMenu}
                disabled={item.tenantID === tenantID}
                onClick={composeFunctions(factorySetTenant(item.tenantID as string), handleClose)}
              >
                {item.logo && <img src={item.logo} className={style.logo} alt={''} />}
                <div className={style.tenantName}>{item?.tenantName || ''}</div>
              </ListItemButton>
            );
          })}
        <ListItemButton onClick={composeFunctions(onLogout, handleClose)}>
          {t('logout')}
        </ListItemButton>
      </Popover>
    </>
  );
});
