import { AxiosResponse } from 'axios';
import { SourceModel } from 'configs/types';
import { DynamicResult } from 'modules/dynamic-service';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { API, apiRtk, makePatchArgs, rtkAdapterDynamicToSource } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IBotCase } from '__generated__/api';
import {
  BotUserProfileCasesInfo,
  GetBotUserProfileCasesInput,
  IGridBotCase,
  IGridBotCasesArgs,
} from './models';

const REVALIDATE_KEY = 'BotCases' as const;
const dynamic = dynamicNamespace<IBotCase>();

export * from './models';
export const apiBotCases = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getBotUserProfileCases: build.query({
      queryFn: async (arg: GetBotUserProfileCasesInput) => {
        const { orderBy, botUserProfileID } = arg;
        const response = await API.api.botCasesGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter('appIdentityUserID', botUserProfileID, equals),
            dynamic.makeFilter('isActive', true, equals),
          ).join('&&'),
          Select: dynamic.select(
            'id',
            'isActive',
            'case.catalogName as title',
            'case.botSessions.Count() as sessionsCount',
          ),
          OrderBy: dynamic.orderBy(orderBy),
        });

        return rtkAdapterDynamicToSource<BotUserProfileCasesInfo>(response as unknown as any);
      },
    }),

    getSourceBotCases: build.query({
      queryFn: async (botID: string) => {
        const response = await API.api.botCasesGetAllDynamicList({
          Select: dynamic.select('id', 'caseID', 'case.catalogName as title', 'isActive'),
          Filter: mergeFilters(dynamic.makeFilter('appIdentityUserID', botID, equals)).join('&&'),
          OrderBy: dynamic.orderBy('case.catalogName', 'asc'),
        });

        return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
      },
      providesTags: (_, __, botID) => [{ type: REVALIDATE_KEY, id: `BOT-${botID}` }],
    }),

    getTotalNewBotCases: build.query({
      queryFn: async (botID: string) => {
        return API.api.botsHelperGetTotalNewCasesDetail(botID);
      },
      providesTags: (_, __, botID) => [{ type: REVALIDATE_KEY, id: `TOTAL-BOT-${botID}` }],
    }),
    syncNewBotCases: build.mutation({
      queryFn: async (botID: string) => {
        return API.api.botsHelperSyncCreate(botID);
      },
      invalidatesTags: (_, __, botID) => [
        { type: REVALIDATE_KEY, id: `TOTAL-BOT-${botID}` },
        { type: REVALIDATE_KEY, id: `BOT-${botID}` },
      ],
    }),

    getGridBotCases: build.query({
      queryFn: async ({
        botID,
        orderBy,
        search,
        country,
        useForVitAssist,
        useForPharmAssist,
        isActive,
      }: IGridBotCasesArgs) => {
        const response = await API.api.botCasesGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter('appIdentityUserID', botID, equals),
            dynamic.makeFilter('case.country.countryName', country, equals),
            dynamic.makeFilter('case.useForVitAssist', useForVitAssist, equals),
            dynamic.makeFilter('case.useForPharmAssist', useForPharmAssist, equals),
            dynamic.makeFilter('case.catalogName', search, contains),
            dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
          ).join('&&'),
          Select: dynamic.select('id', 'caseID', 'isActive', 'case.catalogName as title'),
          OrderBy: dynamic.orderBy(orderBy),
          Count: true,
        });

        return response as AxiosResponse<DynamicResult<IGridBotCase>>;
      },
      providesTags: (_, __, { botID }) => [{ type: REVALIDATE_KEY, id: `BOT-${botID}` }],
    }),

    patchBotCase: build.mutation({
      queryFn: ({
        appIdentityUserID,
        ...rest
      }: PatchPartial<IBotCase, 'id' | 'appIdentityUserID'>) => {
        return API.api.botCasesPatchPartialUpdate(...makePatchArgs(rest));
      },
      invalidatesTags: (_, __, { appIdentityUserID }) => [
        { type: REVALIDATE_KEY, id: `BOT-${appIdentityUserID}` },
      ],
    }),
  }),
});
