import { actionAccountSetTenantID, selectAccountTenantID } from 'store/auth';
import { call, delay, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { notifyErrorSaga, workerErrorNotifyThunk } from 'utils/sagas';
import { parseErrorData } from 'utils/service';
import {
  actionGetDashboardBotSessionLogs,
  actionGetDashboardDosageFormSession,
  actionGetDashboardSessionAnalytics,
  actionGetDashboardSessionProducts,
  actionGetNumberOfBots,
  actionGetNumberOfIgnoredProducts,
  actionGetNumberOfProducts,
} from './actions';
import { selectDashboardDateFilter } from './selectors';

import { actionDashboardInit, actionDashboardSetDateRange } from './slice';

let abortSessionAnalytics: null | Function = null;
let abortSessionDosageForm: null | Function = null;
let abortSessionProducts: null | Function = null;
let abortSessionLogs: null | Function = null;

function* sagaWatchChangeKPIs() {
  try {
    const tenantID = yield* select(selectAccountTenantID);
    const dateRange = yield* select(selectDashboardDateFilter);
    if (tenantID && dateRange.length === 2) {
      // abort previous request
      if (abortSessionAnalytics) {
        abortSessionAnalytics();

        // delay to allow handling an error action first
        yield* delay(500);
      }

      if (abortSessionDosageForm) {
        abortSessionDosageForm();

        yield* delay(500);
      }

      if (abortSessionProducts) {
        abortSessionProducts();

        yield* delay(500);
      }

      if (abortSessionLogs) {
        abortSessionLogs();

        yield* delay(500);
      }

      const resSessionAnalytics = yield* put(
        actionGetDashboardSessionAnalytics({ dateRange, tenantID }),
      );
      const resDosageForm = yield* put(
        actionGetDashboardDosageFormSession({ dateRange, tenantID }),
      );
      const resSessionProducts = yield* put(
        actionGetDashboardSessionProducts({ dateRange, tenantID }),
      );
      const resSessionLogs = yield* put(actionGetDashboardBotSessionLogs({ dateRange, tenantID }));

      abortSessionAnalytics = resSessionAnalytics.abort;
      abortSessionDosageForm = resDosageForm.abort;
      abortSessionProducts = resSessionProducts.abort;
      abortSessionLogs = resSessionLogs.abort;
    }
  } catch (e: any) {
    yield* call(notifyErrorSaga, parseErrorData(e));
  }
}

function* sagaInitDashboard() {
  try {
    const tenantID = yield* select(selectAccountTenantID);
    if (tenantID) {
      yield* sagaWatchChangeKPIs();
      yield* put(actionGetNumberOfBots({ tenantID }));
      yield* put(actionGetNumberOfProducts({ tenantID }));
      yield* put(actionGetNumberOfIgnoredProducts({ tenantID }));
    }
  } catch (e: any) {
    yield* call(notifyErrorSaga, parseErrorData(e));
  }
}

export const sagasDashboard = [
  takeLatest(actionDashboardSetDateRange.type, sagaWatchChangeKPIs),
  takeLatest([actionDashboardInit.type, actionAccountSetTenantID.type], sagaInitDashboard),
  takeEvery(
    [
      actionGetNumberOfBots.rejected,
      actionGetNumberOfProducts.rejected,
      actionGetNumberOfIgnoredProducts.rejected,
    ],
    workerErrorNotifyThunk,
  ),
];
