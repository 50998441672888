import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import { ITenantIgnoreProduct } from '__generated__/api';

export interface IgnoreProductInput extends ITenantIgnoreProduct {}
export const schemaIgnoreProduct = yup.object({
  serialNumber: yup.string().nullable().required('rule-required').trim().default(''),
  productName: yup.string().nullable().required('rule-required').trim().default(''),
});

export interface IGridIgnoredProduct extends ITenantIgnoreProduct {}
export interface IGridIgnoredProductArgs {
  tenantID: string;
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
}
