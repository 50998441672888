import { QueryStatus } from '@reduxjs/toolkit/query';
import cloneDeep from 'lodash/cloneDeep';
import shuffle from 'lodash/shuffle';
import { IBotCaseTenantProductItem, ServiceBiddingBoard } from 'services/bidding-boards';
import { selectAccountTenantID } from 'store/auth';
import {
  actionOrderBoardChangeIndex,
  actionOrderBoardDeactivate,
  actionOrderBoardLoadAllProducts,
  actionOrderBoardLoadProducts,
} from 'store/order-board/actions';
import { all, call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { AppError } from 'utils/errors';
import { randomIntFromInterval } from 'utils/numbers';
import { notifyErrorSaga, workerErrorNotifyThunk } from 'utils/sagas';
import { parseErrorData } from 'utils/service';
import {
  selectOrderBoardFilters,
  selectOrderBoardOrderCases,
  selectOrderBoardOrderCasesGraph,
  selectOrderBoardOrderNotPromotedProducts,
  selectOrderBoardOrderProductsAllPositions,
  selectOrderBoardOrderProductsCurrent,
} from './selectors';
import {
  actionOrderBoardInit,
  actionOrderBoardPreparePreviewItems,
  actionOrderBoardPreparePreviewItems_,
  actionOrderBoardRefresh,
  actionOrderBoardReset,
  actionOrderBoardSetFilters,
  actionOrderBoardSetFilters_,
  actionOrderBoardUpdatePrice,
} from './slice';

function* sagaWatchChangeFilter(action: ReturnType<typeof actionOrderBoardSetFilters>) {
  let { pharmaCompanyOrderID, botCaseID, search, botIDs } = action.payload;

  let graph = yield* select(selectOrderBoardOrderCasesGraph);

  if (!botCaseID) {
    throw new AppError();
  }

  const order = graph[botCaseID];

  if (!order) {
    throw new AppError();
  }

  botIDs = botIDs.length && botIDs[0] in order.bots ? botIDs : Object.keys(order.bots);

  const bot = botIDs ? order.bots[botIDs[0]] : null;

  if (!bot) {
    throw new AppError();
  }

  const updatedPharmaCompanyOrderID =
    pharmaCompanyOrderID && bot.pharmaCompanyOrder[pharmaCompanyOrderID]
      ? pharmaCompanyOrderID
      : '';

  yield* put(
    actionOrderBoardSetFilters_({
      pharmaCompanyOrderID: updatedPharmaCompanyOrderID,
      botCaseID,
      search,
      botIDs,
    }),
  );
}
function* sagaPreparePreviewItems() {
  const mapPositions = yield* select(selectOrderBoardOrderProductsAllPositions);
  const productsNotPromoted = yield* select(selectOrderBoardOrderNotPromotedProducts);

  // non-promoted products
  const productsNotPromotedShuffledList = shuffle(productsNotPromoted);

  // products for preview page
  const products: IBotCaseTenantProductItem[] = [];

  // mapPositions - list of products that are in bidding board. structure:
  //{
  // 0: (2) [{…}, {…}],
  // 1: (2) [{…}, {…}]
  // 7: [{…}]
  // },
  const mapsPositionsUpdated = cloneDeep(mapPositions);

  const setProductsOnSlot = (slotIndex: number) => {
    const currentPosition = mapsPositionsUpdated[slotIndex];

    // check do we have promoted items for current slot
    if (currentPosition?.length) {
      const randomIndex = randomIntFromInterval(0, currentPosition.length - 1);
      products.push(currentPosition[randomIndex]);
      mapsPositionsUpdated[slotIndex]?.splice(randomIndex, 1);
    } else {
      // in case where it's 3 slot - this loop with check if there is any promoted products that are in 1, 2 or 3 slot, but it hadn't been chosen
      for (let i = 0; i <= slotIndex; i++) {
        const promotedSlotItems = mapsPositionsUpdated[i];
        if (promotedSlotItems?.length) {
          const randomIndex = randomIntFromInterval(0, promotedSlotItems.length - 1);
          products.push(promotedSlotItems[randomIndex]);
          promotedSlotItems?.splice(randomIndex, 1);
          break;
        }
      }

      // if we didn't push any promoted items to current slot, we will push one of the non-promoted products
      if (!products[slotIndex]) {
        products.push(productsNotPromotedShuffledList[0]);
        productsNotPromotedShuffledList.splice(0, 1);
      }
    }

    // checking are there any promoted products left. Position index (-1) means that it's includes in non-promoted product list
    const leftPromotedProducts = Object.values(mapsPositionsUpdated).flat();
    const hasPromotedProducts = mapsPositionsUpdated[-1]?.length
      ? mapsPositionsUpdated[-1]?.length !== leftPromotedProducts.length
      : leftPromotedProducts.length;

    // if we have promoted items, we will start choosing item for the next slot
    if (hasPromotedProducts) {
      setProductsOnSlot(slotIndex + 1);
    }
  };

  //start the recursion
  setProductsOnSlot(0);

  yield* put(
    //final list will include the product (that we added thanks to recursion) and productsNotPromotedShuffledList (non-promoted products that are left)
    actionOrderBoardPreparePreviewItems_([...products, ...productsNotPromotedShuffledList]),
  );
}
function* sagaWatchInit(action: ReturnType<typeof actionOrderBoardInit>) {
  const pharmaCompanyOrderID = action.payload;

  const { status } = yield* select(selectOrderBoardOrderCases);

  if (status === QueryStatus.fulfilled) {
    yield put(
      actionOrderBoardSetFilters({
        pharmaCompanyOrderID,
        botCaseID: null,
        search: '',
        botIDs: [],
      }),
    );
  }
}

function* sagaResetProducts() {
  const currentProductsList = yield* select(selectOrderBoardOrderProductsCurrent);
  const currentProductsListActive = currentProductsList.filter((item) => item.isActive);

  yield* all(
    currentProductsListActive.map(({ id }) => {
      return put(actionOrderBoardDeactivate({ id }));
    }),
  );
}

function* sagaWatchChangePrices(action: ReturnType<typeof actionOrderBoardUpdatePrice>) {
  try {
    yield* call(ServiceBiddingBoard.updatePrices, action.payload);
  } catch (e: any) {
    yield* call(notifyErrorSaga, parseErrorData(e));
  }
}
function* sagaWatchRefresh() {
  const { botCaseID } = yield* select(selectOrderBoardFilters);
  const tenantID = yield* select(selectAccountTenantID);

  if (botCaseID && tenantID) {
    yield* put(actionOrderBoardLoadProducts({ botCaseID, tenantID }));
    yield* put(actionOrderBoardLoadAllProducts({ botCaseID, tenantID }));
  }
}

export const sagasOrderBord = [
  takeLatest(actionOrderBoardSetFilters.type, sagaWatchChangeFilter),
  takeLatest(actionOrderBoardPreparePreviewItems.type, sagaPreparePreviewItems),
  takeLatest(actionOrderBoardInit.type, sagaWatchInit),
  takeLatest(actionOrderBoardUpdatePrice.type, sagaWatchChangePrices),
  takeLatest(actionOrderBoardRefresh.type, sagaWatchRefresh),
  takeLatest(actionOrderBoardReset.type, sagaResetProducts),
  takeEvery(
    [
      actionOrderBoardLoadAllProducts.rejected,
      actionOrderBoardLoadProducts.rejected,
      actionOrderBoardChangeIndex.rejected,
      actionOrderBoardDeactivate.rejected,
    ],
    workerErrorNotifyThunk,
  ),
];
