import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  HOME: createTypedRoute('/'),
  DASHBOARD: createTypedRoute('/dashboard'),

  BOTS: createTypedRoute<{ bot?: string } | void>('/bots'),

  PRODUCTS: createTypedRoute('/products'),
  PRODUCTS_LIST: createTypedRoute('/products/list'),
  PRODUCTS_IGNORED: createTypedRoute('/products/ignored'),
  PRODUCTS_ANALYST: createTypedRoute('/products/analyst'),

  PROMOTION: createTypedRoute('/promotion'),
  PROMOTION_ORDERS: createTypedRoute('/promotion/orders'),
  PROMOTION_BOARS: createTypedRoute('/promotion/boards'),
  PROMOTION_ANALYTICS: createTypedRoute('/promotion/analytics'),

  SESSIONS: createTypedRoute('/sessions'),

  SETTINGS: createTypedRoute('/settings'),
  SETTINGS_THEMES: createTypedRoute('/settings/themes'),
  SETTINGS_LABELS_FOR_BOT: createTypedRoute('/settings/labels-for-bot'),
  SETTINGS_LABELS_FOR_CUSTOMER_PORTAL: createTypedRoute('/settings/labels-for-customer-portal'),

  LOGIN: createTypedRoute<{ redirect?: string } | void>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),
  LOGIN_TENANT: createTypedRoute<{ redirect?: string }>('/login/tenant'),
};
