import {
  dateLess,
  dateMore,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { AppError } from 'utils/errors';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicCount,
  rtkAdapterDynamicToSource,
} from 'utils/service';
import { IBotCaseTenantProduct } from '__generated__/api';
import {
  BiddingBoardDeactivateInput,
  BiddingBoardGetProductsInput,
  BiddingBoardUpdatePositionInput,
  BiddingBoardUpdatePricesInput,
  IBiddingBoardCases,
  IBotCaseTenantProductItem,
} from './models';

export * from './models';

class Service {
  async getProducts(input: BiddingBoardGetProductsInput) {
    const { botCaseID, tenantID } = input;
    const response = await API.api.botCaseTenantProductsGetAllDynamicList({
      Filter: mergeFilters(
        dynamic.makeFilter('botCase.caseID', botCaseID, equals),
        dynamic.makeFilter('tenantProduct.tenantID', tenantID, equals),
        dynamic.makeFilter('botCase.botUserProfile.isActive', true, equals),
        dynamic.makeFilter('pharmaCompanyOrder.isActive', true, equals),
        dynamic.makeFilter('pharmaCompanyOrder.startDate', new Date(), dateLess),
        dynamic.makeFilter('pharmaCompanyOrder.endDate', new Date(), dateMore),
      ).join('&&'),
      Select: dynamic.select(
        'botCase',
        'botCaseID',
        'createdDate',
        'id',
        'isActive',
        'pharmaCompanyOrder',
        'pharmaCompanyOrderID',
        'positionIndex',
        'priceForCart',
        'priceForClick',
        'priceForView',
        'tenantProduct.tenantProductContents.Where(k => !String.IsNullOrWhiteSpace(k.media)).Select(k => k.media).FirstOrDefault() as productPicture',
        'tenantProductID as botCaseTenantProductID',
        'updatedDate',
        'tenantProduct.product.catalogName as productName',
        'tenantProduct.product.productBrand.catalogName as productBrandCatalogName',
        'tenantProduct.serialNumber as productSerialNumber',

        'tenantProduct.linkURL as productLinkURL',
        'tenantProduct.price as productPrice',
        'tenantProduct.priceSale as productPriceSale',
        'tenantProduct.priceSaleDescription as productPriceSaleDescription',
      ),
    });
    return rtkAdapterDynamicToSource<IBotCaseTenantProductItem>(response as unknown as any);
  }
  async getAllProducts(input: BiddingBoardGetProductsInput) {
    const { botCaseID, tenantID } = input;
    const response = await API.api.tenantProductsGetAllDynamicList({
      Filter: `product.productCases.any(c => c.caseID=="${botCaseID}")&&tenantID=="${tenantID}"`,
      Select: dynamic.select(
        'product.catalogName as productName',
        'id',
        'linkURL as productLinkURL',
        'priceSale as productPriceSale',
        'price as productPrice',
        'priceSaleDescription as productPriceSaleDescription',
        'serialNumber as productSerialNumber',
        'product.productBrand.catalogName as productBrandCatalogName',
        'updatedDate',
        'createdDate',
        'tenantProductContents.Where(k => !String.IsNullOrWhiteSpace(k.media)).Select(k => k.media).FirstOrDefault() as productPicture',
      ),
      // orderBy: 'product.catalogName',
    });
    return rtkAdapterDynamicToSource<IBotCaseTenantProductItem>(response as unknown as any);
  }
  async getNumberOfProducts(args: { tenantID: string }) {
    const { tenantID } = args;
    const response = await API.api.tenantProductsGetAllDynamicList({
      Filter: mergeFilters(
        dynamic.makeFilter('tenantID', tenantID, equals),
        dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
      ).join('&&'),
      Select: dynamic.select('id'),
      Count: true,
    });
    return rtkAdapterDynamicCount<number>(response as unknown as any);
  }
  updatePosition(input: BiddingBoardUpdatePositionInput) {
    const { id, positionIndex } = input;
    return API.api.botCaseTenantProductsPatchPartialUpdate(
      ...makePatchArgs({
        positionIndex,
        id,
        isActive: true,
      }),
    );
  }
  deactivate(input: BiddingBoardDeactivateInput) {
    const { id } = input;
    return API.api.botCaseTenantProductsPatchPartialUpdate(
      ...makePatchArgs({
        id,
        positionIndex: -1,
        isActive: false,
      }),
    );
  }
  updatePrices(input: BiddingBoardUpdatePricesInput) {
    const { id } = input;

    if (!id) {
      throw new AppError({
        message: 'id should be defined',
      });
    }

    return API.api.botCaseTenantProductsPatchPartialUpdate(...makePatchArgs(input));
  }
}

export const ServiceBiddingBoard = new Service();

const dynamic = dynamicNamespace<IBotCaseTenantProduct>();

export const apiBiddingBoard = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getBiddingBoardCases: build.query<IBiddingBoardCases[], { tenantID: string | null }>({
      queryFn: async ({ tenantID }) => {
        const response = await API.api.botCaseTenantProductsGetAllDynamicList({
          Select: dynamic.select(
            'id',
            'botCase.appIdentityUserID as botID',
            'botCase.caseID as botCaseID',
            'botCase.case.catalogName as caseCatalogName',
            'pharmaCompanyOrderID',
            'pharmaCompanyOrder.orderNumber as pharmaCompanyOrderNumber',
            'botCase.caseID as caseID',
            'botCase.botUserProfile.title as botName',
          ),
          Filter: mergeFilters(
            dynamic.makeFilter('tenantProduct.tenantID', tenantID, equals),
            dynamic.makeFilter('botCase.botUserProfile.isActive', true, equals),
            dynamic.makeFilter('pharmaCompanyOrder.isActive', true, equals),
            dynamic.makeFilter('pharmaCompanyOrder.startDate', new Date(), dateLess),
            dynamic.makeFilter('pharmaCompanyOrder.endDate', new Date(), dateMore),
          ).join('&&'),
          OrderBy: dynamic.orderBy('botCase.case.catalogName', 'asc'),
        });
        return rtkAdapterDynamicToSource<IBiddingBoardCases>(response as unknown as any);
      },
    }),
  }),
});
