import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

const generateSearch = (query: Record<string, any> | string | void | undefined) => {
  if (query && typeof query === 'object') {
    const _query = Object.entries(query).reduce(
      (acc, [key, value]) => {
        if (value !== null && value !== undefined) {
          acc[key] = value;
        }
        return acc;
      },
      {} as typeof query,
    );
    return new URLSearchParams(_query).toString() || undefined;
  } else if (query) {
    return query;
  } else {
    return undefined;
  }
};
const generateConfig = (path: string, params: Record<string, any> = {}) => {
  let copyParams = { ...params };
  const pathname = path
    .split('/')
    .map((item) => {
      if (item.startsWith(':')) {
        const key = item.substring(1);
        if (!params.hasOwnProperty(key)) {
          throw new Error(`TypedRoute/${path}: param ${key} is not exist`);
        }

        const paramValue = params[key];

        delete copyParams[key];

        return paramValue;
      }
      return item;
    })
    .join('/');

  return {
    pathname,
    search: generateSearch(copyParams),
  };
};

type Params = Record<string, any> | void;

export interface TypedRouteResult {
  config: { pathname: string; search: string | undefined };
  link: string;
}
export interface TypedRoute<T extends Params = void> {
  (params: T): TypedRouteResult;
  path: string;
}
export type ExtractParams<T = void> = T extends TypedRoute<infer P>
  ? Partial<Exclude<P, void>>
  : unknown;

export const createTypedRoute = <T extends Params = void>(path: string): TypedRoute<T> => {
  function typedRoute(params: T) {
    const config = generateConfig(path, params || undefined);
    return {
      config,
      link: [config.pathname, config.search].filter((v) => v).join('?'),
    };
  }

  typedRoute.path = path;
  return typedRoute;
};

export const useTypedRouteParams = <T>() => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const searchObject = useMemo(() => {
    return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      // @ts-ignore
      acc[key] = value;
      return acc;
    }, {});
  }, [searchParams]);

  return { ...params, ...searchObject } as ExtractParams<T>;
};
