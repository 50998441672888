import React from 'react';

import style from './index.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, DialogTitleProps, IconButton, Stack, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';

interface Props extends Omit<DialogTitleProps, 'title'> {
  title?: React.ReactNode;
  disabled?: boolean;
  onClose?: () => void;
}

export const DialogHeader: React.FC<Props> = ({
  title,
  onClose,
  className,
  disabled,
  children,
  ...rest
}) => {
  const { tp } = useTranslate();
  return (
    <Stack className={clsx(style.root, className)} direction={'row'} color={'text.secondary'}>
      <DialogTitle
        variant={'h6'}
        textTransform={'capitalize'}
        color={'text.primary'}
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
        {...rest}
      >
        {title}
      </DialogTitle>

      {children}
      {onClose && (
        <IconButton className={style.close} onClick={onClose} disabled={disabled} color={'inherit'}>
          <Tooltip title={tp('close')}>
            <CloseIcon color={'inherit'} />
          </Tooltip>
        </IconButton>
      )}
    </Stack>
  );
};
