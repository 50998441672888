import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCallback, useMemo } from 'react';
import { apiLanguages } from 'services/languages';
import { actionAccountSetLanguageID, selectAccountLanguageID } from 'store/auth';
import { ILanguageDirection } from '../__generated__/api';

export const useLanguage = () => {
  const dispatch = useAppDispatch();
  const languageID = useAppSelector(selectAccountLanguageID);
  const { data: languages = [] } = apiLanguages.useGetAllLanguagesQuery();
  const language = useMemo(() => {
    return languages.find(({ id }) => languageID === id);
  }, [languages, languageID]);
  const setLanguage = useCallback(
    (languageID: string) => {
      dispatch(actionAccountSetLanguageID(languageID));
    },
    [dispatch],
  );

  const isRTL = useMemo(() => {
    return language ? language.direction === ILanguageDirection.RTL : false;
  }, [language]);

  return { languageID, language, languages, setLanguage, isRTL };
};
