import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { NativeScroll, NativeScrollProps } from 'components/native-scroll';
import React from 'react';
import style from './index.module.scss';

interface Props extends NativeScrollProps {
  ContentProps?: Partial<BoxProps>;
}

export const AppDialogContent: React.FC<Props> = ({
  children,
  className,
  ContentProps,
  ...rest
}) => {
  return (
    <NativeScroll className={clsx(style.root, className)} {...rest}>
      <Box {...ContentProps} className={clsx(style.content, ContentProps?.className)}>
        {children}
      </Box>
    </NativeScroll>
  );
};
