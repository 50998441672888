import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBotCaseTenantProductItem, ServiceBiddingBoard } from 'services/bidding-boards';
import {
  selectOrderBoardFilters,
  selectOrderBoardOrderProducts,
} from 'store/order-board/selectors';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';

export const actionOrderBoardLoadProducts = createAsyncThunk<
  IBotCaseTenantProductItem[],
  { botCaseID: string; tenantID: string },
  AppAsyncThunkConfig
>(
  `ORDER_BOARD/actionOrderBoardLoadProducts`,
  async ({ botCaseID, tenantID }) => {
    try {
      const { data } = await ServiceBiddingBoard.getProducts({ botCaseID, tenantID });
      return data;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
  {
    condition: ({ botCaseID }, { getState }) => {
      const filters = selectOrderBoardFilters(getState());
      return botCaseID === filters.botCaseID;
    },
  },
);
export const actionOrderBoardLoadAllProducts = createAsyncThunk<
  IBotCaseTenantProductItem[],
  { botCaseID: string; tenantID: string },
  AppAsyncThunkConfig
>(
  `ORDER_BOARD/actionOrderBoardLoadAllProducts`,
  async ({ botCaseID, tenantID }) => {
    try {
      const { data } = await ServiceBiddingBoard.getAllProducts({ botCaseID, tenantID });
      return data;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
  {
    condition: ({ botCaseID }, { getState }) => {
      const filters = selectOrderBoardFilters(getState());
      return botCaseID === filters.botCaseID;
    },
  },
);
export const actionOrderBoardChangeIndex = createAsyncThunk<
  void,
  { id: string; oldIndex: number; newIndex: number },
  AppAsyncThunkConfig
>(`ORDER_BOARD/actionOrderBoardChangeIndex`, async ({ id, newIndex }) => {
  try {
    await ServiceBiddingBoard.updatePosition({
      id,
      positionIndex: newIndex,
    });
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionOrderBoardDeactivate = createAsyncThunk<
  void,
  { id: string },
  AppAsyncThunkConfig & { rejectedMeta: { positionIndex: number } }
>(`ORDER_BOARD/actionOrderBoardDeactivate`, async ({ id }, { rejectWithValue, getState }) => {
  const products = selectOrderBoardOrderProducts(getState());
  const product = products.find((item) => item.id === id);

  try {
    await ServiceBiddingBoard.deactivate({
      id,
    });
  } catch (e: any) {
    return rejectWithValue(parseErrorData(e), { positionIndex: Number(product?.positionIndex) });
  }
});
