interface GetSearchFilterOptions<T> {
  value: string;
  fields: (keyof T)[];
}
export const getSearchFilter = <T extends Record<string, any>>(
  options: GetSearchFilterOptions<T>,
) => {
  const { value, fields } = options;
  const valueWords = String(value).toLowerCase().replace(/ +/gi, ' ').split(' ');
  return (item: T) =>
    valueWords.every((word) =>
      fields.some((fieldKey) =>
        String(item[fieldKey] || '')
          .toLowerCase()
          .includes(word),
      ),
    );
};
