import { createSelector } from '@reduxjs/toolkit';
import { DASHBOARD_CATEGORY_ALL_OPTION } from 'store/dashboard/slice';
import { AppState } from '../index';

const selectState = (state: AppState) => state.dashboard;

export const selectDashboardDateFilter = createSelector(selectState, (state) => state.dateRange);
export const selectDashboardCategoryFilter = createSelector(selectState, (state) => state.category);
export const selectDashboardNumberOfBots = createSelector(selectState, (state) => ({
  countOfBots: state.numberOfBotsCount,
  isLoadingCountOfBots: state.numberOfBotsLoading,
}));
export const selectDashboardNumberOfProducts = createSelector(selectState, (state) => ({
  countOfProducts: state.numberOfProductsCount,
  isLoadingCountOfProducts: state.numberOfProductsLoading,
}));
export const selectDashboardNumberOfIgnoredProducts = createSelector(selectState, (state) => ({
  countOfIgnoredProducts: state.numberOfIgnoredProductsCount,
  isLoadingCountOfIgnoredProducts: state.numberOfIgnoredProductsLoading,
}));

//entry point
const _selectDashboardSessionsAnalyticsDataFilteredByCategory = createSelector(
  selectState,
  (state) => {
    const categoryID = state.category?.id;
    return categoryID && categoryID !== DASHBOARD_CATEGORY_ALL_OPTION
      ? state.sessionAnalytics.filter((item) => item.caseCategory.catalogName === categoryID)
      : state.sessionAnalytics;
  },
);

const _selectDashboardDosageFormSessionsDataFilteredByCategory = createSelector(
  selectState,
  (state) => {
    const categoryID = state.category?.id;
    return categoryID && categoryID !== DASHBOARD_CATEGORY_ALL_OPTION
      ? state.sessionDosageForm.filter((item) => item.caseCategory.catalogName === categoryID)
      : state.sessionDosageForm;
  },
);

const _selectDashboardSessionsProductsDataFilteredByCategory = createSelector(
  selectState,
  (state) => {
    const categoryID = state.category?.id;
    return categoryID && categoryID !== DASHBOARD_CATEGORY_ALL_OPTION
      ? state.sessionProducts.filter((item) => item.caseCategory.catalogName === categoryID)
      : state.sessionProducts;
  },
);
const _selectDashboardSessionsLogsDataFilteredByCategory = createSelector(selectState, (state) => {
  const categoryID = state.category?.id;
  return categoryID && categoryID !== DASHBOARD_CATEGORY_ALL_OPTION
    ? state.sessionLogs.filter((item) => item.caseCategory.catalogName === categoryID)
    : state.sessionLogs;
});

//outer point
export const selectDashboardSessionsAnalyticsData = createSelector(
  _selectDashboardSessionsAnalyticsDataFilteredByCategory,
  (list) => {
    return list;
  },
);
export const selectDashboardDosageFormSessionsData = createSelector(
  _selectDashboardDosageFormSessionsDataFilteredByCategory,
  (list) => {
    return list;
  },
);
export const selectDashboardSessionsProductsData = createSelector(
  _selectDashboardSessionsProductsDataFilteredByCategory,
  (list) => {
    return list;
  },
);
export const selectDashboardSessionsLogsData = createSelector(
  _selectDashboardSessionsLogsDataFilteredByCategory,
  (list) => {
    return list;
  },
);
export const selectDashboardSessionsAnalyticsInitData = createSelector(selectState, (state) => {
  return state.sessionAnalytics;
});
export const selectDashboardSessionsAnalyticsLoading = createSelector(selectState, (state) => {
  return state.sessionAnalyticsLoading;
});
export const selectDashboardDosageFormSessionsLoading = createSelector(selectState, (state) => {
  return state.sessionDosageFormLoading;
});
export const selectDashboardSessionsProductsLoading = createSelector(selectState, (state) => {
  return state.sessionProductsLoading;
});
export const selectDashboardSessionsLogsLoading = createSelector(selectState, (state) => {
  return state.sessionLogsLoading;
});

const selectDashboardSessionsAnalyticsCompletedData = createSelector(
  selectDashboardSessionsAnalyticsData,
  (sessions) => {
    return sessions.filter((session) => session.botSessionStep?.isSessionComplete);
  },
);

export const selectDashboardNumberOfSessions = createSelector(
  selectDashboardSessionsAnalyticsData,
  (sessions) => {
    return sessions.length;
  },
);
export const selectDashboardNumberOfCompletedSessions = createSelector(
  selectDashboardSessionsAnalyticsCompletedData,
  (sessions) => {
    return sessions.length;
  },
);
