import { AxiosResponse } from 'axios';
import {
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
  orderBy,
  select,
} from 'utils/dynamic';
import { API, apiRtk, rtkAdapterDynamicToSource } from 'utils/service';
import { ICountry } from '__generated__/api';
import { IGridCountry } from './models';

const dynamic = dynamicNamespace<IGridCountry>();

const REVALIDATE_KEY = 'Countries';

export const apiCountries = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceCountries: build.query<IGridCountry[], void>({
      queryFn: async () => {
        try {
          const { data } = await API.api.countriesGetAllDynamicList({
            Select: dynamic.select('id', 'countryName as title'),
            OrderBy: dynamic.orderBy('countryName', 'asc'),
          });
          return { data: data?.items || [] } as { data: IGridCountry[] };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getSourceHighlightCountries: build.query({
      queryFn: async (arg: void) => {
        try {
          const res = await API.api.countriesGetAllDynamicList({
            Select: select('id', 'countryName', 'flag', 'isActive'),
            OrderBy: orderBy('countryName', 'asc'),
            Filter: mergeFilters(
              dynamic.makeFilter('highlight', true, decoratorIsNotNullable(equals)),
            ).join('&&'),
          });

          return rtkAdapterDynamicToSource(res) as AxiosResponse<
            Array<Pick<ICountry, 'id' | 'countryName' | 'flag' | 'isActive'>>
          >;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
  }),
});
