import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  justifyContent?: 'left' | 'center' | 'right';
}

export const AppDialogAction: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <Box className={clsx(style.root, className)} {...rest}>
      {children}
    </Box>
  );
};
