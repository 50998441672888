import { FixServerObject } from 'utils/types';
import * as yup from 'yup';
import { IBotCase, IBotCaseTenantProduct } from '__generated__/api';

export interface IBotCaseTenantProductItem
  extends FixServerObject<
    Pick<
      IBotCaseTenantProduct,
      | 'botCase'
      | 'botCaseID'
      | 'createdDate'
      | 'id'
      | 'isActive'
      | 'pharmaCompanyOrder'
      | 'pharmaCompanyOrderID'
      | 'positionIndex'
      | 'priceForCart'
      | 'priceForClick'
      | 'priceForView'
      | 'updatedDate'
    >,
    'id'
  > {
  productPicture: string;
  botCaseTenantProductID: string;
  _isLoading: boolean;
  productName: string;
  productSerialNumber: number;
  productBrandCatalogName: string;
  productLinkURL: string;
  productPrice: number | null;
  productPriceSale: number | null;
  productPriceSaleDescription: string;
}

export interface IBiddingBoardCases
  extends Pick<IBotCaseTenantProduct, 'pharmaCompanyOrderID' | 'id'>,
    Pick<IBotCase, 'caseID'> {
  botCaseID: string | null;
  caseCatalogName: string;
  pharmaCompanyOrderNumber: string;
  botID: string | null;
  botName: string;
}

export class OrderBoardProductCardModel
  implements Pick<IBotCaseTenantProductItem, 'priceForView' | 'priceForClick' | 'priceForCart'>
{
  priceForView = 0;
  priceForClick = 0;
  priceForCart = 0;
}

export const schemaBiddingBoardProduct = yup.object({
  priceForView: yup.number().required('rule-required'),
  priceForClick: yup.number().required('rule-required'),
  priceForCart: yup.number().required('rule-required'),
});

export interface BiddingBoardGetProductsInput {
  botCaseID: string;
  tenantID: string;
}
export interface BiddingBoardUpdatePositionInput {
  id: string;
  positionIndex: number;
}
export interface BiddingBoardDeactivateInput {
  id: string;
}
export interface BiddingBoardUpdatePricesInput
  extends Pick<
    IBotCaseTenantProductItem,
    'priceForView' | 'priceForClick' | 'priceForCart' | 'id'
  > {}
