import * as yup from 'yup';
yup.addMethod<yup.StringSchema>(yup.string, 'colorHex', function (message: string) {
  return this.test('colorHex', message, function (value) {
    const { path, createError } = this;
    if (!value) return true;
    if (!/^#([0-9A-F]{3}){1,2}$/i.test(String(value))) {
      return createError({ path, message });
    }
    return true;
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'emailFixed', function (message: string) {
  return this.test('email-fixed', message, function (value) {
    const { path, createError } = this;
    if (!value) return true;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi.test(String(value))) {
      return createError({ path, message });
    }
    return true;
  });
});

export * from 'yup';
export default yup;
