export enum APP_ERROR_CODES {
  UNEXPECTED = 1,
}
interface AppErrorOptions {
  message?: string;
  code?: APP_ERROR_CODES;
}

export class AppError extends Error {
  code: APP_ERROR_CODES;
  constructor(options?: AppErrorOptions) {
    const { code = APP_ERROR_CODES.UNEXPECTED, message } = options || {};
    super(message);
    this.code = code;
  }
}
