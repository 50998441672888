import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, useTheme } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import clsx from 'clsx';
import style from './index.module.scss';

type ArrowProps = React.HTMLProps<HTMLButtonElement>;

const SamplePrevArrow: React.FC<ArrowProps> = (props) => {
  const { className, onClick } = props;
  return (
    <IconButton className={clsx(className, style.button, style.buttonPrev)} onClick={onClick}>
      <ArrowBackIosNewIcon />
    </IconButton>
  );
};

const SampleNextArrow: React.FC<ArrowProps> = (props) => {
  const { className, onClick } = props;
  return (
    <IconButton className={clsx(className, style.button, style.buttonNext)} onClick={onClick}>
      <ArrowForwardIosIcon />
    </IconButton>
  );
};

interface Props {
  children: React.ReactNode;
}

export const SlickSlider: React.FC<Props> = ({ children }) => {
  const theme = useTheme();

  return (
    <Slider
      autoplay
      dots
      autoplaySpeed={5000}
      speed={500}
      rows={1}
      nextArrow={<SampleNextArrow />}
      prevArrow={<SamplePrevArrow />}
      dotsClass={style.dots}
      responsive={[
        {
          breakpoint: theme.breakpoints.values.md,
          settings: {
            arrows: false,
          },
        },
      ]}
    >
      {children}
    </Slider>
  );
};
