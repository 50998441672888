import * as yup from 'yup';

export const schemaBotWelcomeMessage = yup.object({
  // hidden
  id: yup.string().nullable().default(null),
  languageID: yup.string().required('rule-required').default(''),

  //visible
  message: yup.string().nullable().required('rule-required').default(''),
  dialogMaxWidth: yup.number().typeError('rule-number').min(0, 'rule-min-length').default(0),
  welcomeSubTitle: yup.string().nullable().notRequired().default(''),
  productSubTitleLabel: yup.string().nullable().notRequired().default(''),
  helpMeLabel: yup.string().nullable().notRequired().default(''),
  termsAndPrivacyLabel: yup.string().nullable().notRequired().default(''),
  disclaimer: yup.string().nullable().notRequired().default(''),
  logo: yup.mixed().nullable().notRequired().default(''),
  welcomeBanner: yup.mixed().nullable().notRequired().default(''),
  termsAndConditionsHtmlContent: yup.string().nullable().notRequired().default(''),
  privacyPolicyHtmlContent: yup.string().nullable().notRequired().default(''),
  isActive: yup.boolean().default(true),
});

export interface BotWelcomeMessageGet extends yup.InferType<typeof schemaBotWelcomeMessage> {
  id: string;
  languageID: string;
}

export interface BotWelcomeMessageInputPatch extends yup.InferType<typeof schemaBotWelcomeMessage> {
  id: string;
}
