import { Link, Link as MaterialLink, Theme, Typography, useMediaQuery } from '@mui/material';
import { useOpen, useTranslate } from 'hooks';
import { useLanguage } from 'hooks/use-language';
import { memo } from 'react';
import { apiPages } from 'services/pages';
import { ContainerDialogPrivacy } from '../container-dialog-privacy';
import { ContainerDialogTerms } from '../container-dialog-terms';
import style from './index.module.scss';

const SITE_URL = process.env.REACT_APP_WEBSITE_URL as string;
interface Props {}

export const Footer = memo<Props>(() => {
  const { t, tp } = useTranslate();
  const { languageID } = useLanguage();
  const { data } = apiPages.useGetPageLandingQuery(languageID || '', { skip: !languageID });

  const { isOpen: isOpenPrivacy, onToggle: onTogglePrivacy } = useOpen();
  const { isOpen: isOpenTerms, onToggle: onToggleTerms } = useOpen();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <>
      <footer className={style.footer}>
        <Typography variant={'body2'} color={isMobile ? 'primary.contrastText' : 'text.secondary'}>
          {data?.copyright && (
            <div dangerouslySetInnerHTML={{ __html: data.copyright as string }} />
          )}
        </Typography>

        <div className={style.links}>
          <Link
            component={'a'}
            href={'#privacy'}
            title={t('privacy-policy')}
            onClick={onTogglePrivacy}
            variant={'body2'}
            underline={'hover'}
            color={'primary.contrastText'}
          >
            {t('privacy-policy')}
          </Link>
          <Link
            component={'a'}
            href={'#terms'}
            title={t('terms-and-conditions')}
            onClick={onToggleTerms}
            variant={'body2'}
            underline={'hover'}
            color={'primary.contrastText'}
          >
            {t('terms-and-conditions')}
          </Link>
          <MaterialLink
            color={'primary.contrastText'}
            variant={'body2'}
            underline={'hover'}
            href={SITE_URL}
            target={'_blank'}
          >
            {tp('why-pharmassist')}
          </MaterialLink>
        </div>
      </footer>

      {isOpenPrivacy && <ContainerDialogPrivacy onClose={onTogglePrivacy} />}
      {isOpenTerms && <ContainerDialogTerms onClose={onToggleTerms} />}
    </>
  );
});
