import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import {
  actionAccountSetTenantID,
  selectAccountTenant,
  selectAccountTenantID,
  selectAccountTenants,
} from 'store/auth';

export const useTenant = () => {
  const dispatch = useAppDispatch();
  const tenantID = useAppSelector(selectAccountTenantID);
  const tenant = useAppSelector(selectAccountTenant);
  const tenants = useAppSelector(selectAccountTenants);

  const setTenant = useCallback(
    (tenantID: string) => {
      dispatch(actionAccountSetTenantID(tenantID));
    },
    [dispatch],
  );

  return { tenantID, tenant, tenants, setTenant };
};
