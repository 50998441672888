import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiBiddingBoard, IBotCaseTenantProductItem } from 'services/bidding-boards';
import { actionAccountLogout } from 'store/auth/actions';
import {
  actionOrderBoardChangeIndex,
  actionOrderBoardDeactivate,
  actionOrderBoardLoadAllProducts,
  actionOrderBoardLoadProducts,
} from 'store/order-board/actions';

export interface OrderBoardProduct extends IBotCaseTenantProductItem {
  _isLoading: boolean;
}

interface InitStateFilters {
  pharmaCompanyOrderID: string | null;
  botCaseID: string | null;
  botIDs: string[];
  search: string;
}
interface InitStateSettings {
  isHidePrices: boolean;
  isShowPreview: boolean;
}
interface InitStatePagination {
  page: number;
  take: number;
}
interface InitStateProducts {
  products: IBotCaseTenantProductItem[];
  productsLoading: boolean;
}
interface InitStateProductsAll {
  productsAll: IBotCaseTenantProductItem[];
  productsAllLoading: boolean;
}

interface InitState
  extends InitStateFilters,
    InitStateSettings,
    InitStateProducts,
    InitStateProductsAll,
    InitStatePagination {
  isDragging: boolean;
  isInit: boolean;
  maxPositions: number;
  previewItems: IBotCaseTenantProductItem[];
}

const initState = (): InitState => {
  return {
    isInit: false,
    isDragging: false,
    maxPositions: 12,

    pharmaCompanyOrderID: null,
    botCaseID: null,
    search: '',

    botIDs: [],
    isHidePrices: true,
    isShowPreview: false,

    page: 1,
    take: 12,

    products: [],
    productsLoading: false,

    productsAll: [],
    productsAllLoading: false,
    previewItems: [],
  };
};

const slice = createSlice({
  name: 'ORDER_BOARD',
  initialState: initState(),
  reducers: {
    actionOrderBoardInit(state, action: PayloadAction<string>) {
      state.pharmaCompanyOrderID = action.payload;
      state.isInit = true;
    },
    actionOrderBoardSetDragging(state, action: PayloadAction<boolean>) {
      state.isDragging = action.payload;
    },
    actionOrderBoardDestroy() {
      return initState();
    },
    actionOrderBoardUpdatePrice(
      state,
      action: PayloadAction<
        Pick<OrderBoardProduct, 'id' | 'priceForView' | 'priceForClick' | 'priceForCart'>
      >,
    ) {
      const { id, ...rest } = action.payload;
      state.products = state.products.map((item) => {
        if (item.id !== id) return item;
        return { ...item, ...rest };
      });
    },
    actionOrderBoardSetFilters_(state, action: PayloadAction<InitStateFilters>) {
      if (state.botCaseID === action.payload.botCaseID) {
        state.page = 1;
      }
      Object.entries(action.payload).forEach(([key, value]) => {
        // @ts-ignore
        state[key] = value;
      });
    },
    actionOrderBoardSetFilters(state, action: PayloadAction<InitStateFilters>) {},
    actionOrderBoardSetSettings(state, action: PayloadAction<InitStateSettings>) {
      Object.entries(action.payload).forEach(([key, value]) => {
        // @ts-ignore
        state[key] = value;
      });
    },
    actionOrderBoardRefresh(state) {},
    actionOrderBoardReset(state) {},
    actionOrderBoardPreparePreviewItems(state) {},
    actionOrderBoardPreparePreviewItems_(state, action) {
      state.previewItems = action.payload;
    },
    actionOrderBoardPreviewSetPage(state, action) {
      state.page = action.payload;
    },
    actionOrderBoardUpdateIDs(state, action) {
      const { botID, isChecked } = action.payload;
      if (isChecked) {
        const index = state.botIDs.indexOf(botID);
        state.botIDs.splice(index, 1);
      } else {
        state.botIDs.push(botID);
      }
    },
    actionOrderBoardSetIDs(state, action) {
      state.botIDs = action.payload;
    },
    actionOrderBoardResetBotID(state) {
      state.botIDs = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionOrderBoardChangeIndex.pending, (state, action) => {
        state.products = state.products.map((item) => {
          if (item.id !== action.meta.arg.id) {
            return item;
          }
          return {
            ...item,
            isActive: true,
            _isLoading: true,
            positionIndex: action.meta.arg.newIndex,
          };
        });
      })
      .addCase(actionOrderBoardChangeIndex.fulfilled, (state, action) => {
        state.products = state.products.map((item) => {
          if (item.id !== action.meta.arg.id) {
            return item;
          }
          return { ...item, _isLoading: false };
        });
      })
      .addCase(actionOrderBoardChangeIndex.rejected, (state, action) => {
        state.products = state.products.map((item) => {
          if (item.id !== action.meta.arg.id) {
            return item;
          }
          return {
            ...item,
            positionIndex: action.meta.arg.oldIndex,
            isActive: action.meta.arg.oldIndex > -1,
            _isLoading: false,
          };
        });
      });

    builder
      .addCase(actionOrderBoardDeactivate.pending, (state, action) => {
        state.products = state.products.map((item) => {
          if (item.id !== action.meta.arg.id) {
            return item;
          }
          return {
            ...item,
            isActive: false,
            _isLoading: true,
            positionIndex: -1,
          };
        });
      })
      .addCase(actionOrderBoardDeactivate.fulfilled, (state, action) => {
        state.products = state.products.map((item) => {
          if (item.id !== action.meta.arg.id) {
            return item;
          }
          return { ...item, _isLoading: false };
        });
      })
      .addCase(actionOrderBoardDeactivate.rejected, (state, action) => {
        state.products = state.products.map((item) => {
          if (item.id !== action.meta.arg.id) {
            return item;
          }
          const positionIndex =
            action.meta.positionIndex === undefined ? -1 : action.meta.positionIndex;

          return {
            ...item,
            positionIndex,
            isActive: positionIndex > -1,
            _isLoading: false,
          };
        });
      });

    builder
      .addCase(actionOrderBoardLoadProducts.pending, (state) => {
        state.productsLoading = true;
      })
      .addCase(actionOrderBoardLoadProducts.fulfilled, (state, action) => {
        state.productsLoading = false;
        state.products = action.payload.map((item) => ({ ...item, _isLoading: false }));
      })
      .addCase(actionOrderBoardLoadProducts.rejected, (state) => {
        state.productsLoading = false;
      });

    builder
      .addCase(actionOrderBoardLoadAllProducts.pending, (state) => {
        state.productsAllLoading = true;
      })
      .addCase(actionOrderBoardLoadAllProducts.fulfilled, (state, action) => {
        state.productsAllLoading = false;
        state.productsAll = action.payload.map((item) => ({ ...item, _isLoading: false }));
      })
      .addCase(actionOrderBoardLoadAllProducts.rejected, (state) => {
        state.productsAllLoading = false;
      });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });

    builder.addMatcher(
      apiBiddingBoard.endpoints.getBiddingBoardCases.matchFulfilled,
      (state, action) => {
        let first = action.payload.find(
          (item) => item.pharmaCompanyOrderID === state.pharmaCompanyOrderID,
        );

        first = first ?? action.payload[0];

        // state.pharmaCompanyOrderID = first.pharmaCompanyOrderID || null;
        state.botCaseID = first?.botCaseID || null;
        state.page = 1;
      },
    );
  },
});
export const {
  actionOrderBoardInit,
  actionOrderBoardSetDragging,
  actionOrderBoardSetFilters,
  actionOrderBoardSetSettings,
  actionOrderBoardSetFilters_,
  actionOrderBoardUpdatePrice,
  actionOrderBoardRefresh,
  actionOrderBoardReset,
  actionOrderBoardDestroy,
  actionOrderBoardUpdateIDs,
  actionOrderBoardSetIDs,
  actionOrderBoardResetBotID,
  actionOrderBoardPreviewSetPage,
  actionOrderBoardPreparePreviewItems,
  actionOrderBoardPreparePreviewItems_,
} = slice.actions;
export const reducerOrderBoard = slice.reducer;
