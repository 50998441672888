import { dynamicNamespace, equals, mergeFilters, moreOrEquals } from 'utils/dynamic';

import { SourceModel } from 'configs/types';
import { API, apiRtk, rtkAdapterDynamicToSource } from 'utils/service';
import { IProduct } from '__generated__/api';

const dynamic = dynamicNamespace<IProduct>();
export const apiProducts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceProducts: build.query({
      queryFn: async (arg: void) => {
        const response = await API.api.productsGetAllDynamicList({
          Select: dynamic.select('id', 'catalogName as title'),
          Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
          OrderBy: dynamic.orderBy('catalogName', 'asc'),
        });
        return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
      },
    }),
    getSourceProductsByCompanyID: build.query({
      queryFn: async ({
        pharmaCompanyID,
        countryID,
      }: {
        pharmaCompanyID: string;
        countryID: string;
      }) => {
        const response = await API.api.productsGetAllDynamicList({
          Select: dynamic.select('id', 'catalogName as title'),
          Filter: mergeFilters(
            dynamic.makeFilter('isActive', true, equals),
            dynamic.makeFilter('pharmaCompanyID', pharmaCompanyID, equals),
            dynamic.makeFilter('country.id', countryID, equals),
            dynamic.makeFilter(
              'tenantProducts.Where(k => !k.isPending&&isActive==true&&serialNumber.length > 2).count()',
              1,
              moreOrEquals,
            ),
          ).join('&&'),
          OrderBy: dynamic.orderBy('catalogName', 'asc'),
        });
        return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
      },
    }),
  }),
});
