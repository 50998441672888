import { useMemo } from 'react';

function map<T extends string | number | Date>(
  source: T[],
  key?: void,
): Record<string, T | undefined>;
function map<T extends Record<string, any> = { id: string }>(
  source: T[],
  key: keyof T,
): Record<string, T | undefined>;
function map(source: any[], key: any = 'id') {
  const firstItem = source[0];

  if (!firstItem) {
    return {};
  }

  if (typeof firstItem[0] === 'string' || firstItem[0] instanceof Date) {
    return source.reduce((acc, key) => {
      acc[String(key)] = key;
      return acc;
    }, {});
  }
  return source.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
}

function _useMap<T extends string | number | Date>(
  source: T[],
  key?: void,
): Record<string, T | undefined>;
function _useMap<T extends Record<string, any> = { id: string }>(
  source: T[],
  key: keyof T,
): Record<string, T | undefined>;
function _useMap(source: any[], key: any = 'id') {
  return useMemo(() => {
    return map(source, key);
  }, [source, key]);
}
export const useMap = _useMap;
