import { AxiosResponse } from 'axios';
import {
  IChartAgeData,
  IChartAgeGroupData,
  IChartAgeGroupMatrixData,
  IChartCasesOrCategoriesData,
  IChartCityData,
  IChartCountriesData,
  IChartDailyScenarioCategoryData,
  IChartMonthHeatmapData,
  IChartScenarios,
  IChartSessionsPerBotData,
} from 'utils/chart-helpers';
import { DateValue } from 'utils/dates';
import {
  contains,
  dateRange,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import { IBotSession } from '__generated__/api';
import {
  BotSessionAnalyticsItem,
  DashboardSessionsArgs,
  GetBotSessionDetailsItem,
  IGridBotSessionsParams,
  IGridBotSessionsResponse,
  TGetBotSessionDetailsInput,
} from './models';

const REVALIDATE_KEY = 'BotSessions' as const;

const dynamic = dynamicNamespace<IBotSession>();

export * from './models';
class Service {
  getDashboardSessionAnalytics = async (input: DashboardSessionsArgs) => {
    return this.getDashboardSessionAnalyticsFromApi(input);
  };

  private getDashboardSessionAnalyticsFromApi = async (
    input: DashboardSessionsArgs,
  ): Promise<AxiosResponse<BotSessionAnalyticsItem[]>> => {
    const response = await API.api.botSessionsGetAllDynamicList({
      ...this.makeDashboardSessionAnalyticsParams(input),
    });

    return rtkAdapterDynamicToSource<BotSessionAnalyticsItem>(response as unknown as any);
  };

  private makeDashboardSessionAnalyticsParams(input: DashboardSessionsArgs) {
    const { tenantID, dateRange: dateRangeValue } = input;

    return {
      Filter: mergeFilters(
        dynamic.makeFilter('tenantID', tenantID, equals),
        dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
      ).join('&&'),
      Select: dynamic.select(
        'createdDate',
        'age',
        'longitude',
        'latitude',
        'location',
        'new { botSessionStep.isSessionComplete } as botSessionStep',
        'new { botUserProfile.id, botUserProfile.title } as botUserProfile',
        'new { case.id, case.catalogName } as case',
        'new { case.caseCategory.id, case.caseCategory.catalogName } as caseCategory',
        // 'botSessionProducts.Select(l=> l.tenantProduct.product.productBrand.catalogName ) as productBrand',
        // 'botSessionDosageForms.Select(l=> new { dosageForm.catalogName, dosageForm.id } ) as botSessionDosageForms',
        // 'botSessionLogs.Select(l=> new { id, eventDate, userActionKey } ) as botSessionLogs',
        // 'botSessionLogs.OrderBy(l=> l.orderIndex).Select(l=>l.eventDate).FirstOrDefault() as sessionStartDate',
        // 'botSessionLogs.OrderByDescending(l=> l.orderIndex).Select(l=>l.eventDate).FirstOrDefault() as sessionEndDate',
      ),
      Count: true,
    };
  }
}

export const ServiceBotSessions = new Service();

export const apiBotSessions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridBotSessions: build.query({
      queryFn: (params: IGridBotSessionsParams) =>
        API.api
          .botSessionsGetAllDynamicList({
            Select: dynamic.select(
              'id',
              'createdDate',
              'caseID',
              'new { botUserProfile.title, botUserProfile.icon } as botUserProfile',
              'case',
              'new { botSessionStep.isSessionComplete, botSessionStep.flowCompletePercentage, botSessionStep.title } as botSessionStep',
              'gender.catalogName as genderCatalogName',
              'botSessionProducts.Count() as productsCount',
              'botSessionProducts.Where(p => p.isPromoted).Count() as productsPromotedCount',
              'deviceType',
              'age',
              'location',
              'botUserProfileID',
            ),
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', params.tenantID, decoratorIsNotNullable(equals)),
              dynamic.makeFilter(
                ['case.catalogName', 'gender.catalogName', 'botUserProfile.title', 'location'],
                params.search,
                contains,
              ),
              dynamic.makeFilter('createdDate', params.date, dateRange),
              dynamic.makeFilter('botUserProfileID', params.botID, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('caseID', params.caseID, decoratorIsNotNullable(equals)),
              dynamic.makeFilter(
                'botSessionStep.isSessionComplete',
                params.isSessionComplete,
                decoratorIsNotNullable(equals),
              ),
            ).join('&&'),
            OrderBy: dynamic.orderBy(params.orderBy.field, params.orderBy.order),
            Take: params.take,
            Skip: params.skip,
            Count: true,
          })
          .catch(rtkAdapterError) as Promise<AxiosResponse<IGridBotSessionsResponse>>,
      providesTags: (_, __, arg) => [{ type: REVALIDATE_KEY, id: `grid__tenant-${arg.tenantID}` }],
    }),
    getBotSessionDetails: build.query({
      queryFn: async (input: TGetBotSessionDetailsInput) => {
        const { id } = input;
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(dynamic.makeFilter('id', id, equals)).join('&&'),
            Select: dynamic.select(
              'id',
              'age',
              'location',
              'languageID',
              'createdDate',
              'deviceType',
              `new { botSessionStep.flowCompletePercentage, botSessionStep.isSessionComplete, botSessionStep.title } as botSessionStep`,
              `new { gender.catalogName } as gender`,
              `new { language.title, language.culture } as language`,
              'new { botUserProfile.title, botUserProfile.icon, botUserProfile.currency } as botUserProfile',
              'new { case.useForVitAssist, case.useForPharmAssist, case.catalogName } as case',
              'botSessionProducts.Count() as productsCount',
              'botSessionDosageForms.Select(i=> new { i.dosageForm.catalogName }) as dosageForms',
              'botSessionMedicalConditions.Select(i=> new { i.medicalCondition.catalogName }) as medicalConditions',
              'botSessionSensitivities.Select(i=> new { i.sensitivity.catalogName }) as sensitivities',
              'botSessionDietaryPreferences.Select(i=> new { i.dietaryPreference.catalogName }) as dietaryPreferences',
            ),
          });
          return rtkAdapterDynamicItem<GetBotSessionDetailsItem>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getAgeGroupSessions: build.query<
      IChartAgeGroupData[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select('age'),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartAgeGroupData>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getCasesOrCategoriesSessions: build.query<
      IChartCasesOrCategoriesData[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select(
              'new { case.id, case.catalogName } as case',
              'new { case.caseCategory.id, case.caseCategory.catalogName } as caseCategory',
            ),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartCasesOrCategoriesData>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getAgeSessions: build.query<IChartAgeData[], { tenantID: string; dateRangeValue: DateValue[] }>(
      {
        queryFn: async ({ tenantID, dateRangeValue }) => {
          try {
            const response = await API.api.botSessionsGetAllDynamicList({
              Filter: mergeFilters(
                dynamic.makeFilter('tenantID', tenantID, equals),
                dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
                dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
              ).join('&&'),
              Select: dynamic.select('new { case.id, case.catalogName } as case', 'age'),
              Count: true,
            });
            return rtkAdapterDynamicToSource<IChartAgeData>(response as unknown as any);
          } catch (e) {
            return rtkAdapterError(e as unknown as any);
          }
        },
      },
    ),
    getMonthSessions: build.query<
      IChartMonthHeatmapData[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select('new { case.id, case.catalogName } as case', 'createdDate'),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartMonthHeatmapData>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getPerBotSessions: build.query<
      IChartSessionsPerBotData[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select(
              'new { botUserProfile.id, botUserProfile.title } as botUserProfile',
              'createdDate',
            ),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartSessionsPerBotData>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getScenariosSessions: build.query<
      IChartScenarios[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select(
              'new { case.id, case.catalogName } as case',
              'new { botSessionStep.isSessionComplete } as botSessionStep',
            ),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartScenarios>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getDailyScenarioCategorySessions: build.query<
      IChartDailyScenarioCategoryData[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select('new { case.id, case.catalogName } as case', 'createdDate'),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartDailyScenarioCategoryData>(
            response as unknown as any,
          );
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getCountriesSessions: build.query<
      IChartCountriesData[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select(
              'longitude',
              'latitude',
              'location',
              'new { botSessionStep.isSessionComplete } as botSessionStep',
              'new { case.id, case.catalogName } as case',
              'new { case.caseCategory.id, case.caseCategory.catalogName } as caseCategory',
            ),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartCountriesData>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getCitySessions: build.query<
      IChartCityData[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select('location', 'new { case.id, case.catalogName } as case'),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartCityData>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
    getAgeGroupMatrix: build.query<
      IChartAgeGroupMatrixData[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('tenantID', tenantID, equals),
              dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select(
              'age',
              'new { case.caseCategory.id, case.caseCategory.catalogName } as caseCategory',
            ),
            Count: true,
          });
          return rtkAdapterDynamicToSource<IChartAgeGroupMatrixData>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
  }),
});
