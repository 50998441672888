import { isNumber } from 'lodash-es';

interface Options {
  x: number;
  y0: number;
  y1: number;
  x0: number;
  x1: number;
}

export const interpolation = (options: Options) => {
  const { x, y1, y0, x0, x1 } = options;
  return y0 + ((y1 - y0) / (x1 - x0)) * (x - x0);
};

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const numberFormat = (count: number, options?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat('en-US', options).format(count);

export const numberUS = (num: null | string | number | undefined, precision?: number) => {
  if (!isNumber(num)) {
    return '';
  }

  return Number(num).toLocaleString('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};
