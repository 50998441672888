import { APP_ROUTES } from 'configs';
import { useRedirectPath } from 'hooks/use-redirect-path';
import { useTenant } from 'hooks/use-tenant';
import React, { memo } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}
export const RequireTenant = memo<Props>(({ children }) => {
  const { tenantID } = useTenant();

  const redirect = useRedirectPath();

  if (!tenantID) {
    return <Navigate to={APP_ROUTES.LOGIN_TENANT({ redirect }).config} replace={true} />;
  }

  return <>{children}</>;
});
