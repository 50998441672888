import { AxiosResponse } from 'axios';
import { PRODUCTS_PER_PAGE } from 'configs/const';
import { DashboardSessionsArgs } from 'services/bot-sessions';
import { DateValue } from 'utils/dates';
import {
  dateRange,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  calcPaginationSkip,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import { IBotSessionProduct } from '__generated__/api';
import {
  BotSessionProductItem,
  GetBotSessionProductsByBotSessionIDArg,
  GetBotSessionProductsByBotSessionIDResponse,
} from './models';
export * from './models';

const dynamic = dynamicNamespace<IBotSessionProduct>();

class Service {
  getDashboardSessionProducts = async (input: DashboardSessionsArgs) => {
    return this.getDashboardSessionAnalyticsFromApi(input);
  };

  private getDashboardSessionAnalyticsFromApi = async (
    input: DashboardSessionsArgs,
  ): Promise<AxiosResponse<BotSessionProductItem[]>> => {
    const response = await API.api.botSessionProductsGetAllDynamicList({
      ...this.makeDashboardSessionProducts(input),
    });

    return rtkAdapterDynamicToSource<BotSessionProductItem>(response as unknown as any);
  };

  private makeDashboardSessionProducts(input: DashboardSessionsArgs) {
    const { tenantID, dateRange: dateRangeValue } = input;

    return {
      Filter: mergeFilters(
        dynamic.makeFilter('botSession.tenantID', tenantID, equals),
        dynamic.makeFilter('botSession.isActive', true, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('botSession.createdDate', dateRangeValue, dateRange),
      ).join('&&'),
      Select: dynamic.select(
        'id, new { botSession.case.caseCategory.id, botSession.case.caseCategory.catalogName } as caseCategory',
        'tenantProduct.product.productBrand.catalogName as productBrand',
      ),
      Count: true,
    };
  }
}

export const ServiceBotSessionsProducts = new Service();

export const apiBotSessionProducts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    infinityGetBotSessionProductsByBotSessionID: build.query({
      queryFn: async (input: GetBotSessionProductsByBotSessionIDArg) => {
        const { botSessionID, languageID, page } = input;
        const result = await API.api.botSessionProductsGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter('botSessionID', botSessionID, equals),
            dynamic.makeFilter('isActive', true, equals),
          ).join('&&'),
          Select: dynamic.select(
            'id',
            'isPromoted',
            'isActive',
            'score',
            'isFavorite',
            'new { tenantProduct.linkURL, tenantProduct.priceSale, tenantProduct.price, tenantProduct.priceSaleDescription } as tenantProduct',
            `tenantProduct.tenantProductContents.Where(c => c.languageID == "${languageID}").Select(c => new { c.title, c.media }).FirstOrDefault() as productContent`,
            `tenantProduct.product.dosageForm.dosageFormContents.Where(c => c.languageID == "${languageID}").Select(c => new { c.title, c.icon }).FirstOrDefault() as dosageFormContent`,
          ),
          OrderBy: [
            dynamic.orderBy('isPromoted', 'desc'),
            dynamic.orderBy('positionIndex', 'asc'),
          ].join(','),
          Count: true,
          Take: PRODUCTS_PER_PAGE,
          Skip: calcPaginationSkip({ page, take: PRODUCTS_PER_PAGE }),
        });

        return result as unknown as AxiosResponse<GetBotSessionProductsByBotSessionIDResponse>;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        [endpointName, queryArgs.botSessionID, queryArgs.languageID].join('__'),
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newCache, { arg: { page } }) => {
        const skip = calcPaginationSkip({ page: page, take: PRODUCTS_PER_PAGE });
        const oldItems = (currentCache.items || []).slice(0, skip);
        currentCache.items = [...oldItems, ...newCache.items];
        currentCache.count = newCache.count;
      },
    }),
    getTopBrands: build.query<
      BotSessionProductItem[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionProductsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('botSession.tenantID', tenantID, equals),
              dynamic.makeFilter('botSession.isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('botSession.createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select(
              'id, new { botSession.case.caseCategory.id, botSession.case.caseCategory.catalogName } as caseCategory',
              'tenantProduct.product.productBrand.catalogName as productBrand',
            ),
            Count: true,
          });
          return rtkAdapterDynamicToSource<BotSessionProductItem>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
  }),
});
