import { AxiosResponse } from 'axios';
import { DynamicResult } from 'modules/dynamic-service';
import { DashboardSessionsArgs } from 'services/bot-sessions';
import { DateValue } from 'utils/dates';
import * as dynamic from 'utils/dynamic';
import { dateRange, decoratorIsNotNullable, equals, mergeFilters } from 'utils/dynamic';
import { API, apiRtk, rtkAdapterDynamicToSource, rtkAdapterError } from 'utils/service';
import {
  BotSessionDosageForm,
  BotSessionDosageFormItem,
  GetSourceBotSessionDosageFormsByBotSessionIDArg,
} from './models';
export * from './models';

class Service {
  getDashboardDosageFormSession = async (input: DashboardSessionsArgs) => {
    return this.getDashboardFormDosage(input);
  };
  private getDashboardFormDosage = async (
    input: DashboardSessionsArgs,
  ): Promise<AxiosResponse<BotSessionDosageForm[]>> => {
    const response = await API.api.botSessionDosageFormsGetAllDynamicList({
      ...this.makeDashboardDosageSessions(input),
    });

    return rtkAdapterDynamicToSource<BotSessionDosageForm>(response as unknown as any);
  };
  private makeDashboardDosageSessions(input: DashboardSessionsArgs) {
    const { tenantID, dateRange: dateRangeValue } = input;

    return {
      Filter: mergeFilters(
        dynamic.makeFilter('botSession.tenantID', tenantID, equals),
        dynamic.makeFilter('botSession.isActive', true, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('botSession.createdDate', dateRangeValue, dateRange),
      ).join('&&'),
      Select: dynamic.select(
        'id, new { dosageForm.catalogName, dosageForm.id } as botSessionDosageForms, new { botSession.case.caseCategory.id, botSession.case.caseCategory.catalogName } as caseCategory',
      ),
      Count: true,
    };
  }
}

export const ServiceDosageFormSessions = new Service();

export const apiBotSessionDosageForms = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceBotSessionDosageFormsByBotSessionID: build.query({
      queryFn: async (arg: GetSourceBotSessionDosageFormsByBotSessionIDArg) => {
        const result = (await API.api.botSessionDosageFormsGetAllDynamicList({
          Filter: dynamic
            .mergeFilters(dynamic.makeFilter('botSessionID', arg.botSessionID, dynamic.equals))
            .join('&&'),
          Select: dynamic.select(
            'dosageFormID as id',
            `dosageForm.dosageFormContents.Where(c => c.languageID == "${arg.languageID}").Select(c => new { c.title, c.icon }).FirstOrDefault() as dosageFormContent`,
          ),
        })) as unknown as AxiosResponse<DynamicResult<BotSessionDosageFormItem>>;

        return {
          ...result,
          data: result.data.items.map((item) => ({
            id: item.id,
            title: item.dosageFormContent.title,
            icon: item.dosageFormContent.icon,
          })),
        };
      },
    }),
    getDashboardFormDosage: build.query<
      BotSessionDosageForm[],
      { tenantID: string; dateRangeValue: DateValue[] }
    >({
      queryFn: async ({ tenantID, dateRangeValue }) => {
        try {
          const response = await API.api.botSessionDosageFormsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter('botSession.tenantID', tenantID, equals),
              dynamic.makeFilter('botSession.isActive', true, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('botSession.createdDate', dateRangeValue, dateRange),
            ).join('&&'),
            Select: dynamic.select(
              'id, new { dosageForm.catalogName, dosageForm.id } as botSessionDosageForms, new { botSession.case.caseCategory.id, botSession.case.caseCategory.catalogName } as caseCategory',
            ),
            Count: true,
          });
          return rtkAdapterDynamicToSource<BotSessionDosageForm>(response as unknown as any);
        } catch (e) {
          return rtkAdapterError(e as unknown as any);
        }
      },
    }),
  }),
});
