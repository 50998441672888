import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Collapse,
  ListItemButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { navigationEmitter } from 'layouts/private/emitter';
import { resolvePath, To, useLocation, useNavigate } from 'react-router-dom';
import style from './index.module.scss';

const checkIsActive = (
  option: Pick<NavigationOption, 'to' | 'options'>,
  pathname: string,
): boolean => {
  const { to, options } = option;
  const path = to && resolvePath(to).pathname;

  const isActiveCurrent = path === pathname;

  if (isActiveCurrent) {
    return true;
  }

  if (options) {
    return options.some((op) => checkIsActive(op, pathname));
  }

  return false;
};

export interface NavigationOption {
  title: string;
  Icon?: React.ReactNode;
  to?: To;
  options?: NavigationOption[];
  onClick?: () => void;
}

export interface NavigationProps extends NavigationOption {
  isMenuOpen?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  level: number;
}

export const NavigationItem: React.FC<NavigationProps> = ({
  Icon,
  title,
  options,
  to,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  level,
  onClick,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const subItems = useMemo(() => {
    return options || [];
  }, [options]);

  const isActive = useMemo(() => {
    return checkIsActive({ to, options }, pathname);
  }, [to, pathname, options]);

  const [isOpen, setIsOpen] = useState(isActive);

  const componentID = useId();

  useEffect(() => {
    const onToggleCollapse = (params: { componentID: string; value: boolean }) => {
      if (params.componentID === componentID) {
        setIsOpen(params.value);
      } else {
        setIsOpen(false);
      }
    };
    if (subItems.length > 0) {
      navigationEmitter.on('onToggleCollapse', onToggleCollapse);
    }
    return () => {
      navigationEmitter.off('onToggleCollapse', onToggleCollapse);
    };
  }, [subItems, componentID]);

  const primary = title;

  const onClickButton = useCallback(() => {
    if (onClick) {
      onClick();
    }
    if (to) {
      onMenuClose && onMenuClose();
      navigate(to);
    } else {
      onMenuOpen && onMenuOpen();
      navigationEmitter.emit('onToggleCollapse', {
        componentID,
        value: !isMenuOpen ? true : !isOpen,
      });
    }
  }, [to, navigate, onMenuClose, onMenuOpen, componentID, isOpen, isMenuOpen, onClick]);

  const isCollapseOpen = isMenuOpen && isOpen;
  const isInner = level > 1;
  const isLast = subItems.length === 0;

  const MenuTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.secondary,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.secondary,
      fontSize: '1.4rem',
      padding: '.8rem 1.5rem',
    },
  }));

  return (
    <>
      <MenuTooltip title={isMenuOpen ? '' : primary} arrow disableInteractive placement={'right'}>
        <ListItemButton
          disableGutters
          className={clsx(style.item, {
            [style.itemInner]: isInner,
            [style.itemLast]: isLast,
            [style.itemSelected]: isActive,
            [style.itemOpen]: isMenuOpen,
          })}
          onClick={onClickButton}
          selected={isActive}
        >
          {isMenuOpen ? (
            <Typography
              component={'div'}
              color={'text.secondary'}
              className={clsx(style.itemLong, isActive && style.itemLongActive)}
            >
              <Box lineHeight={1} fontSize={'2.4rem'} height={'2.4rem'}>
                {Icon}
              </Box>

              <Typography
                className={clsx(
                  style.itemText,
                  !isLast && style.itemTextArrow,
                  isInner && style.itemTextInner,
                )}
                component={'div'}
                lineHeight={1.2}
                fontWeight={isInner ? 400 : 400}
              >
                {primary}
              </Typography>
              {!isLast && <ExpandMore className={clsx(style.arrow, isOpen && style.arrowOpen)} />}
            </Typography>
          ) : (
            <Typography
              component={'div'}
              fontSize={'2.2rem'}
              lineHeight={1}
              textAlign={'center'}
              className={clsx(style.itemSmall, isActive && style.active)}
            >
              <Box className={style.itemSmallBox}>
                {Icon}
                {!isLast && (
                  <div className={style.arrowWrapper}>
                    <ChevronRightIcon className={style.itemSmallArrow} />
                  </div>
                )}
              </Box>
              {/*<Typography*/}
              {/*  component={'div'}*/}
              {/*  fontSize={'1rem'}*/}
              {/*  textAlign={'center'}*/}
              {/*  fontWeight={isActive ? 400 : 400}*/}
              {/*  sx={{*/}
              {/*    overflowWrap: 'break-word',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {primary}*/}
              {/*</Typography>*/}
            </Typography>
          )}
        </ListItemButton>
      </MenuTooltip>
      {!isLast && (
        <Collapse in={isCollapseOpen}>
          {subItems.map((option, i) => {
            return (
              <Box key={i}>
                <NavigationItem
                  isMenuOpen={isMenuOpen}
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  title={option.title}
                  to={option.to}
                  level={level + 1}
                  Icon={option.Icon}
                  onClick={option.onClick}
                />
              </Box>
            );
          })}
        </Collapse>
      )}
    </>
  );
};
