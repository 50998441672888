import { useEffect, useRef } from 'react';

export const useMountedRef = () => {
  const ref = useRef(true);
  const simulation = useRef(false);

  useEffect(() => {
    if (!simulation.current) {
      ref.current = true;
      simulation.current = true;
    }

    Promise.resolve().then(() => (simulation.current = false));
    return () => {
      if (!simulation.current) {
        ref.current = false;
      }
    };
  }, []);

  return ref;
};
