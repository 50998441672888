import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosResponseTransformer } from 'axios';
import { DynamicResult } from 'modules/dynamic-service';
import { Kill } from 'utils/types';
import { Api } from '__generated__/api';
import { equals } from './dynamic';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}?((\.\d+)|$)/gi.test(String(value));
};

export const axiosDateTransformer: AxiosResponseTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const [_dateString, _ms] = value.split('.');
        const _dateNumber = Date.parse(_dateString);

        if (_dateNumber) {
          const ms = _ms || '000';
          // ms has no timezone | only numbers
          if (/^[0-9]+$/gi.test(ms)) {
            return `${_dateString}.${ms}Z`;
          }
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export const API = new Api();
export const apiApp = API.instance;
apiApp.defaults.transformResponse = [axiosDateTransformer];

export const APIFree = new Api();
export const apiFree = APIFree.instance;
apiFree.defaults.transformResponse = [axiosDateTransformer];

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError | Error> => async (config) => {
    try {
      const result = await apiApp({
        method: 'get',
        ...config,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: err,
      };
    }
  };

export const normalizeForm = <T extends Record<string, any>>(data: T): Kill<T> => {
  return Object.keys(data).reduce(
    (acc, key) => {
      if (acc[key] === null) {
        // @ts-ignore
        acc[key] = '';
      }
      return acc;
    },
    { ...data },
  );
};
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Labels',
    'TenantProductContents',
    'Languages',
    'Products',
    'PRODUCTS_INFO',
    'Bots',
    'BotCases',
    'BotThemes',
    'BotSessions',
    'BotWelcomeMessages',
    'BotsHelper',
    'BiddingActions',
    'PharmaCompanyOrderHelpers',
    'PharmaCompanyOrders',
    'PharmaCompanyOrderDetails',
    'Settings',
    'IgnoredProducts',
    'TenantProducts',
    'TenantProductsHelper',
    'PharmaCompanyOrdersHelper',
    'Cases',
    'TenantIgnoredProducts',
    'Countries',
  ],
  endpoints: () => ({}),
});

export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error>) => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { message: 'error', ...errorData };
  }
  return new Error(error.message);
};

export const isRejectedMutation = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isFulfilledMutation = <T>(mutationResult: any): mutationResult is { data: T } => {
  return Boolean(
    mutationResult && mutationResult.hasOwnProperty && mutationResult.hasOwnProperty('data'),
  );
};

export const transformResponseDynamic = <T>(data: { value: T[] }) => {
  return data.value;
};

export const calcPaginationSkip = ({ page, take }: { take: number; page: number }) => {
  return take * (page - 1);
};
export const calcPaginationState = ({
  take,
  page,
  count,
}: {
  take: number;
  count: number;
  page: number;
}) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.ceil(count / take);
  const isLastPage = pages === page;
  const isFirstPage = page === 1;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isFirstPage,
    isLastPage,
  };
};

const prepareFieldWithID = (value: any, key: any) => {
  return value === '' && String(key).endsWith('ID') ? null : value;
};

export const prepareRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    const value = acc[key];
    acc[key] = prepareFieldWithID(value, key);
    return acc;
  }, data);
};
export const preparePatchRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  return Object.entries(prepareRequestData(data))
    .filter(([, value]) => value !== undefined)
    .map(([path, value]) => {
      return { path, value };
    });
};

export const makePatchArgs = <
  T extends Record<string, any> = { id: string },
  K extends keyof T = 'id',
>(
  data: T,
  field: K = 'id' as K,
) => {
  const { [field]: id, ...rest } = data;
  return [String(id), preparePatchRequestData(rest)] as const;
};

export const getByIDRequestHelper = async <M extends Record<string, any>>({
  apiFunc,
  value,
  params = {},
  field = 'id' as keyof M,
}: {
  apiFunc: (...args: any[]) => Promise<
    AxiosResponse<{
      items: M[];
    }>
  >;
  value: M[keyof M];
  params?: {};
  field?: keyof M;
}) => {
  const response = await apiFunc({ Filter: equals(String(field), value), Take: 1, ...params });
  const data = response.data.items[0] as M;
  if (!data) {
    throw new Error('record-not-found');
  } else {
    return { ...response, data };
  }
};

export const rtkAdapterDynamicToSource = <Result>(
  response: AxiosResponse<DynamicResult<Result>>,
) => {
  return { ...response, data: response.data.items } as AxiosResponse<Result[]>;
};
export const rtkAdapterDynamicItem = <Result>(response: AxiosResponse<DynamicResult<Result>>) => {
  const data = response.data.items[0];

  if (!data) {
    throw new Error('record-not-found');
  }
  return { ...response, data } as AxiosResponse<Result>;
};

export const rtkAdapterDynamicCount = <Result>(response: AxiosResponse<DynamicResult<Result>>) => {
  const data = response.data.count;

  return { ...response, data } as AxiosResponse<Result>;
};

export const rtkAdapterError = (response: AxiosError) => {
  return { error: response };
};
