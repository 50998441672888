import { API, apiFree, apiRtk } from 'utils/service';

const fetchHtml = async (url: string | null | undefined) => {
  if (!url) {
    return Promise.resolve({ data: '' });
  }

  return apiFree.get<string>(url);
};

export const apiPages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getPageLanding: build.query({
      queryFn: (languageID: string) => API.api.pagesGetLandingPageDetail(languageID),
    }),
    getPageTerms: build.query({
      queryFn: async (languageID: string) => {
        const result = await API.api.pagesGetTermsPageDetail(languageID);

        const { data: htmlContent } = await fetchHtml(result.data.htmlContent);
        return { ...result, data: { ...result.data, htmlContent: htmlContent } };
      },
    }),
    getPagePrivacy: build.query({
      queryFn: async (languageID: string) => {
        const result = await API.api.pagesGetPrivacyPageDetail(languageID);

        const { data: htmlContent } = await fetchHtml(result.data.htmlContent);
        return { ...result, data: { ...result.data, htmlContent: htmlContent } };
      },
    }),
  }),
});
