import { useCallback, useEffect } from 'react';
import { DefaultValues, FieldValues, useForm, UseFormProps } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues = FieldValues, TContext extends object = object>
  extends UseFormProps<TFieldValues, TContext> {
  defaultValues: DefaultValues<TFieldValues>;
  onUpdate?: (value: TFieldValues) => void;
}
export const useFilterForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>(
  props: Props<TFieldValues, TContext>,
) => {
  const result = useForm(props);

  const { defaultValues, onUpdate } = props;

  const { reset, getValues } = result;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onChangeItem = useCallback(() => {
    if (JSON.stringify(defaultValues) !== JSON.stringify(getValues())) {
      onUpdate && onUpdate(getValues());
    }
  }, [getValues, defaultValues, onUpdate]);

  return { ...result, onChangeItem };
};
